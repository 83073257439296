import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import type {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import {
  Card,
  Dropdown,
  ErrorMessage,
  ExternalLink,
  Input,
  LabelContent,
  Textarea,
  UnitInput,
} from "components";
import { isAgreeCautionsState } from "stores";
import { getLanguageCode, onlyNumber } from "utils";
import { CheckCircleIcon } from "assets";
import { ERROR_MSG } from "constants/index";
import type { OrderForm } from "types";
import { PreviewImg } from "./containers";
import { useGetDropdown, useSelectItemType } from "./hooks";
import * as S from "./PackageInfo.styled";

interface PackageInfoProps {
  errors: FieldErrors<OrderForm>;
  watch: UseFormWatch<OrderForm>;
  register: UseFormRegister<OrderForm>;
  setValue: UseFormSetValue<OrderForm>;
}

const PackageInfo = ({
  errors,
  watch,
  register,
  setValue,
}: PackageInfoProps) => {
  const { t } = useTranslation();

  const isAgreeCautions = useRecoilValue(isAgreeCautionsState);

  const languageCode = getLanguageCode();
  const { dropdownItemTypes } = useGetDropdown();
  const { selectItemType, handleSelectItemType } = useSelectItemType(
    watch,
    setValue,
    dropdownItemTypes,
  );

  if (!dropdownItemTypes) return null;

  return (
    <Card css={S.card} title="Package information">
      <LabelContent isRequired direction="vertical" label="Type of items">
        <Dropdown
          placeholder="Select type"
          options={dropdownItemTypes}
          selectedOption={selectItemType}
          handleSelect={handleSelectItemType}
        />
        {watch("item.itemType") && (
          <Input
            maxLength={100}
            placeholder="Enter the item type"
            register={register("item.name")}
          />
        )}
        <S.ItemCautionsWrapper>
          {isAgreeCautions && (
            <S.CautionsAgreement>
              <CheckCircleIcon css={S.checkCircleIcon} />
              <span>{t("I hereby confirm the delivery restrictions.")}</span>
            </S.CautionsAgreement>
          )}
          <ExternalLink
            css={S.link}
            href={`${process.env.REACT_APP_RESTRICTIONS_SITE}/${languageCode}`} // TODO: 링크 주소 변경 시 수정 필요
            variant="ghostPrimary"
          >
            {t("Check prohibited items")}
          </ExternalLink>
        </S.ItemCautionsWrapper>
      </LabelContent>
      <LabelContent isRequired direction="vertical" label="Total weight">
        <UnitInput
          css={S.totalWeight}
          hasError={!!errors.item?.weight}
          maxLength={2}
          unit="kg"
          register={register("item.weight", {
            required: ERROR_MSG.FIELD,
            validate: (value) => +value <= 50,
            onChange: (e) =>
              setValue("item.weight", onlyNumber(e.target.value)),
          })}
        />
        <S.Description css={S.descWeight} hasError={!!errors.item?.weight}>
          {t(
            "Please contact our help center(☎️ 1999) for the items exceeding 50kg.",
          )}
        </S.Description>
        {errors.item?.weight?.message && (
          <ErrorMessage message={errors.item.weight.message} />
        )}
      </LabelContent>
      <LabelContent isRequired direction="vertical" label="Upload photo">
        <PreviewImg
          hasError={!!errors.item?.photo}
          imgFile={watch("item.photo")}
          setValue={setValue}
          register={register}
        />
        <S.Description>{t("File format : jpg, jpeg, png")}</S.Description>
      </LabelContent>
      <LabelContent direction="vertical" label="Delivery note" marginBottom={0}>
        <Textarea
          css={S.note}
          maxLength={200}
          placeholder="(e.g. valuables, fragile goods, food etc.)"
          value={watch("item.memo") ?? ""}
          register={register("item.memo")}
        />
      </LabelContent>
    </Card>
  );
};

export default PackageInfo;
