import Cookies from "js-cookie";

import { LANGUAGE } from "assets";
import { COOKIE_KEY } from "constants/index";
import type { LoginServerModel } from "types";

export type UserCB = (user: LoginServerModel | null, error: any) => void;

export class Auth {
  private static instance: Auth | null = null;

  static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  private key = COOKIE_KEY;

  private user: LoginServerModel | null = null;

  private cb: UserCB | null = null;

  get isAuth() {
    return !!this.user?.refreshToken;
  }

  get accessToken() {
    return this.user?.accessToken;
  }

  get refreshToken() {
    return this.user?.refreshToken;
  }

  get langCode() {
    return this.user?.langCode;
  }

  changeLanguageCode(languageCode: keyof typeof LANGUAGE) {
    if (!this.user) return;

    const newUser = { ...this.user, langCode: languageCode };
    Cookies.set(this.key, JSON.stringify(newUser));
    this.setUser(newUser);
  }

  changeAccessToken(token: string) {
    const newUser = { ...this.user, accessToken: token };
    Cookies.set(this.key, JSON.stringify(newUser));
    this.setUser(newUser as LoginServerModel);
  }

  changeRefreshToken(token: string) {
    const newUser = { ...this.user, refreshToken: token };
    Cookies.set(this.key, JSON.stringify(newUser));
    this.setUser(newUser as LoginServerModel);
  }

  onAuthStateChanged(cb: UserCB) {
    this.cb = cb;
    this.onUserChange(this.user);

    return () => {
      this.cb = null;
    };
  }

  private onUserChange(
    user: LoginServerModel | null,
    error?: { message: string },
  ) {
    if (this.cb) {
      this.cb(user, error);
    }
  }

  private setUser(user: LoginServerModel) {
    this.user = user;
  }

  signIn(data: LoginServerModel) {
    Cookies.set(this.key, JSON.stringify(data));
    this.onUserChange(data);
    this.resolveUser();
  }

  signOut() {
    Cookies.remove(this.key);
    this.onUserChange(null);
    this.user = null;
  }

  resolveUser() {
    if (window) {
      const signedInUser = Cookies.get(this.key);

      if (signedInUser) {
        this.setUser(JSON.parse(Cookies.get(this.key)!));
      }
    }

    return this;
  }
}
