import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const AutoCompleteWrapper = styled.div`
  position: relative;
`;

export const OpenMapButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.color.gray_35};
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: ${theme.color.gray_00};

    & > svg {
      width: 20px;
      height: 20px;
    }

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const AutocompleteInput = styled.input<{
  isDropdownOpen: boolean;
  hasError: boolean;
}>`
  ${({ isDropdownOpen, hasError, theme }) => css`
    ${theme.font.regular_14};
    flex: 1;
    height: 40px;
    border: 1px solid
      ${hasError
        ? theme.color.red_20
        : isDropdownOpen
        ? theme.color.gray_90
        : theme.color.gray_30};
    border-radius: 2px;
    padding: 0 39px 0 11px;
    color: ${theme.color.black};

    ::focus {
      border: 1px solid brown;
    }

    ::placeholder {
      color: ${theme.color.gray_40};
    }

    &:focus-within + button > svg path {
      fill: ${theme.color.gray_90};
    }
  `}
`;

interface DropdownWrapperProps {
  isActive: boolean;
}

export const DropdownWrapper = styled.ul<DropdownWrapperProps>`
  ${({ theme, isActive }) => css`
    ${theme.scrollbar};
    position: absolute;
    display: ${isActive ? "block" : "none"};
    width: 500px;
    max-height: 200px;
    margin-top: 4px;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.white};
    z-index: 10;
  `}
`;

export const DropdownButton = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14}
    width: 100%;
    padding: 9px 12px;
    color: ${theme.color.gray_80};
    background-color: ${theme.color.white};
    text-align: left;

    :hover {
      background-color: ${theme.color.gray_10};

      > span {
        color: ${theme.color.gray_70};
      }
    }
  `}
`;

export const Loading = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 40px;
    background-color: ${theme.color.white};
  `}
`;

export const AddrAutoComplete = styled.div`
  position: relative;
  width: 100%;
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const SearchButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 55px;
    background-color: ${theme.color.white};
    transform: translateY(-50%);
  `}
`;

export const locationIcon = (theme: Theme) => css`
  & > g > path {
    fill: ${theme.color.gray_60};
  }
`;
