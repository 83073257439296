import type { Languages } from "types";

export const ERROR_MSG: { [key: string]: Languages } = {
  FIELD: "Please fill out this field.",
  MOBILE_VALID: "Please enter 10~15 digit number.",
  INVALID_TIME: "Please select a time at least 30 minutes from now.",
};

export const AUTH_ALERT_MSG = {
  INVALID_REFRESH_TOKEN: "Your session has expired.",
  DUPLICATED_SIGNIN_DETECTED:
    "Signed out because your account is signed in from another device.",
};

export const ERROR_CODE = {
  EMAIL_EXISTS: "EMAIL_EXISTS",
  EMAIL_NOT_FOUND: "EMAIL_NOT_FOUND",
  DRIVING_TRUCK: "DRIVING_TRUCK",
  INVALID_ACCESS_TOKEN: "INVALID_ACCESS_TOKEN",
  INVALID_REFRESH_TOKEN: "INVALID_REFRESH_TOKEN",
  DUPLICATED_SIGNIN_DETECTED: "DUPLICATED_SIGNIN_DETECTED",
} as const;
