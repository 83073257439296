import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Card = css`
  width: 364px;

  & > div:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const Time = styled.span<{ hasReqDatetime: boolean }>`
  ${({ theme, hasReqDatetime }) => css`
    ${hasReqDatetime ? theme.font.regular_13 : theme.font.regular_12};
    display: flex;
    align-items: center;
    grid-column-start: 2;
    width: 100%;
    color: ${hasReqDatetime ? theme.color.black : theme.color.gray_70};
  `}
`;

export const Weight = styled.div<{ isPackaging: boolean }>`
  ${({ theme, isPackaging }) => css`
    ${theme.font.regular_14};
    width: 100%;
    margin-bottom: ${isPackaging && "12px"};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 8px;
    padding: 12px 0;
    border-bottom: 1px solid ${theme.color.gray_30};
  `}
`;

export const IconWrapper = styled.div`
  padding-top: 5px;
`;

export const PointIconWrapper = styled.div`
  ${({ theme }) => css`
    padding-top: 5px;

    svg > path {
      fill: ${theme.color.orange_20};
    }
  `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
  width: 280px;
`;

export const Address = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_13};
  `}
`;

export const AddressDetail = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
    white-space: pre-wrap;
    word-break: break-word;
  `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 280px;
`;

export const ItemType = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    overflow: hidden;
    white-space: nowrap;
  `}
`;

export const ItemName = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 4px;
    color: ${theme.color.gray_60};
    white-space: pre-wrap;
    word-break: break-word;
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 8px;
  `}
`;

export const MoneyWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    width: 100%;
    color: ${theme.color.gray_60};

    & > div:nth-of-type(4) {
      border-bottom: 1px solid ${theme.color.gray_20};
      padding-bottom: 12px;
    }
  `}
`;

export const MoneyRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    & > div {
      ${theme.font.regular_13};

      &:last-of-type {
        color: ${theme.color.black};
      }
    }
  `}
`;

export const TotalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    & > div {
      ${theme.font.bold_15};
      color: ${theme.color.black};
    }
  `}
`;

export const OtherService = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    margin-bottom: 4px;
    color: ${theme.color.black};
  `}
`;
