import React, { useState, memo, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useRecoilState } from "recoil";
import { isEmpty } from "lodash-es";

import { ArrowIcon } from "assets/icon";
import { recentSearchCountryCode } from "stores/persist";
import { getCountryList, getFilterCountries } from "utils";
import type { Country, CountryNumberListClientModel } from "types";
import useDropdown from "../hooks/useDropdown";
import * as S from "../Dropdown.styled";

interface DropdownOptionBtnProps {
  country: Country;
  selectedOption: string;
  handleClickOption: (option: string) => () => void;
}

const DropdownOptionBtn = ({
  country,
  selectedOption,
  handleClickOption,
}: DropdownOptionBtnProps) => {
  return (
    <S.CustomOption>
      <S.CustomOptionBtn
        type="button"
        data-selected={selectedOption === country.code}
        onClick={handleClickOption(country.code)}
      >
        <S.CountryCode>{country.code}</S.CountryCode>
        <S.CountryInfo>{`${country.name} (${country.dial})`}</S.CountryInfo>
        <S.CustomCheckboxIcon />
      </S.CustomOptionBtn>
    </S.CustomOption>
  );
};

export type CountryListDropdownProps = {
  className?: string;
  disabled?: boolean;
  hasErr?: boolean;
  selectedCountry: Country;
  countries?: CountryNumberListClientModel;
  handleSelectCountryWithCode: (code: string) => void;
};

const CountryListDropdown = ({
  className,
  disabled,
  hasErr,
  selectedCountry,
  countries,
  handleSelectCountryWithCode,
}: CountryListDropdownProps) => {
  const id = uuidv4();
  const fixedCountryCode = ["LA"];
  const [selectedOption, setSelectedOption] = useState("");
  const [recentValues, setRecentValues] = useRecoilState<string[]>(
    recentSearchCountryCode,
  );

  const handleSelect = (code: string) => {
    const newRecentValues = [code, ...recentValues];

    setSelectedOption(code);
    handleSelectCountryWithCode(code);
    setRecentValues(
      newRecentValues.filter((item, i) => newRecentValues.indexOf(item) === i),
    );
  };

  const { dropdownRef, optionsRef, isOpen, handleOpener, handleClickOption } =
    useDropdown(selectedOption, handleSelect);

  const fixedCountries = useMemo(
    () => getCountryList(fixedCountryCode, countries),
    [countries],
  );

  const recentCountries = useMemo(
    () => getCountryList(recentValues, countries),
    [recentValues, countries],
  );

  const filteredRecentCountries = useMemo(
    () => getFilterCountries(recentCountries, fixedCountryCode),
    [recentCountries],
  );

  const filteredRemainCountries = useMemo(
    () => (countries ? getFilterCountries(countries, recentValues) : []),
    [countries, recentValues],
  );

  useEffect(() => {
    const initSelectedCountry = !recentValues.includes(selectedCountry.code)
      ? [selectedCountry.code]
      : [];

    setRecentValues([...initSelectedCountry, ...recentValues]);
  }, []);

  useEffect(() => {
    if (isEmpty(selectedCountry)) return;

    setSelectedOption(selectedCountry.code);
  }, [selectedCountry]);

  return (
    <S.Dropdown ref={dropdownRef} className={className}>
      <S.CustomDropdownBtn
        aria-controls={id}
        aria-expanded={isOpen}
        data-haserr={hasErr}
        disabled={disabled}
        type="button"
        onClick={handleOpener}
      >
        <span>{selectedOption}</span>
        <ArrowIcon />
      </S.CustomDropdownBtn>
      <S.CustomOptionWrapper
        ref={optionsRef}
        aria-hidden={!isOpen}
        id={id}
        isOpen={isOpen}
      >
        {Object.values(fixedCountries)
          ?.filter(Boolean)
          .map((country) => (
            <DropdownOptionBtn
              key={country.code}
              country={country}
              selectedOption={selectedOption}
              handleClickOption={handleClickOption}
            />
          ))}

        {filteredRecentCountries?.filter(Boolean).map((country) => (
          <DropdownOptionBtn
            key={country.code}
            country={country}
            selectedOption={selectedOption}
            handleClickOption={handleClickOption}
          />
        ))}

        {countries &&
          filteredRemainCountries?.map((country) => (
            <DropdownOptionBtn
              key={country.code}
              country={country}
              selectedOption={selectedOption}
              handleClickOption={handleClickOption}
            />
          ))}
      </S.CustomOptionWrapper>
    </S.Dropdown>
  );
};

export default memo(CountryListDropdown);
