import React, { useCallback } from "react";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";

import * as S from "./SingleDate.styled";

interface SingleDateProps {
  className?: string;
  isThisMonth?: boolean;
  currentDate: dayjs.Dayjs[] | [];
  date: dayjs.Dayjs;
  disabled?: boolean;
  changeMonth?: () => void;
  handleClick?: (date: dayjs.Dayjs[]) => () => void;
}

const SingleDate = ({
  className,
  isThisMonth,
  currentDate,
  date,
  disabled,
  changeMonth,
  handleClick,
}: SingleDateProps) => {
  const isToday = dayjs().isSame(date, "d");

  const handleClickDate = useCallback(() => {
    handleClick && handleClick([date])();

    if (!isThisMonth) {
      changeMonth && changeMonth();
    }
  }, [date]);

  return (
    <S.Root
      className={className}
      aria-selected={
        !isEmpty(currentDate) && date.isSame(currentDate[0], "day")
      }
      isToday={isToday}
      isThisMonth={isThisMonth ?? false}
    >
      <S.Btn type="button" disabled={disabled} onClick={handleClickDate}>
        {date.format("D")}
      </S.Btn>
    </S.Root>
  );
};

export default SingleDate;
