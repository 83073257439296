export const size = {
  HEADER_HEIGHT: "52px",
  FOOTER_HEIGHT: "76px",
  NAV_WIDTH: "62px",
  LAYOUT_PADDING: "40px",
  LIST_LAYOUT_HEADER_HEIGHT: "50px",
  LIST_LAYOUT_MAIN_TAB_HEIGHT: "50px",
  FOLDABLE_SHORT_LAYOUT_WIDTH: "390px",
  FOLDABLE_WIDE_LAYOUT_WIDTH: "780px",
  TABLE_SECTION_MIN_WIDTH: "1378px", // 1298px (padding 40+40)
  TABLE_SECTION_MAX_WIDTH: "1740px", // 1660px (padding 40+40)
  TABLE_INQUIRY_WIDTH: "698px",
  TABLE_BODY_HEIGHT: "819px",
  GOOGLE_MAP_LEFT_PADDING: 390,
} as const;

export type SizeType = typeof size;
