import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const card = css`
  width: 564px;

  & > div:first-of-type {
    margin-bottom: 24px;
  }
`;

export const InputWrapper = styled.div<{ isSelectCoupon: boolean }>`
  ${({ theme, isSelectCoupon }) => css`
    display: flex;
    justify-content: ${isSelectCoupon ? "space-between" : "flex-end"};
    align-items: center;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding-bottom: 6px;
    background-color: transparent;
  `}
`;

// TODO: 버튼 시스템이 통일되어 있지 않아 디자이너님께 요청 후 정리되면 수정 예정(하기 코드 삭제 필요)
export const selectButton = css`
  height: inherit;
  padding: 5px 11px;
`;

export const CouponDeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const CouponDiscount = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_90};
  `}
`;

export const CouponDeleteButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: inherit;
`;
