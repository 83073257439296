import React, { useCallback, useState } from "react";
import type {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import {
  AddrAutoComplete,
  Card,
  CountryListDropdown,
  ErrorMessage,
  Input,
  LabelContent,
} from "components";
import { useCountryList, usePrompt } from "hooks";
import { maxLengthPhoneNumber, onlyNumber, removeFirstStringZero } from "utils";
import { ERROR_MSG } from "constants/index";
import type {
  AddrAutoCompleteInfo,
  OrderForm,
  ExtractType,
  ExtractCopiedUrl,
} from "types";
import * as S from "./RecipientInfo.styled";

interface RecipientInfoProps {
  watch: UseFormWatch<OrderForm>;
  errors: FieldErrors<OrderForm>;
  clearErrors: UseFormClearErrors<OrderForm>;
  register: UseFormRegister<OrderForm>;
  setValue: UseFormSetValue<OrderForm>;
  resetAddrInfo: () => void;
  handleAddrChange: () => void;
  handleAddrBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleAddrCopy: (
    extractType: ExtractType,
    data: ExtractCopiedUrl["data"],
  ) => void;
  handleAddrSelect: (value: AddrAutoCompleteInfo) => void;
}

const RecipientInfo = ({
  watch,
  errors,
  register,
  setValue,
  clearErrors,
  resetAddrInfo,
  handleAddrChange,
  handleAddrBlur,
  handleAddrCopy,
  handleAddrSelect,
}: RecipientInfoProps) => {
  const [inputValue, setInputValue] = useState("");

  const { selectedCountry, countries, handleSelectCountryWithCode } =
    useCountryList(watch("dropoff.countryCode"));
  usePrompt(!!inputValue);

  const handleSelectInput = (value: string) => setInputValue(value);

  const handleSelectCountryInputReset = useCallback((code: string) => {
    setValue("dropoff.countryCode", code);

    if (selectedCountry) {
      setValue("dropoff.phone", "");
    }
    return handleSelectCountryWithCode(code);
  }, []);

  return (
    <Card
      css={S.card}
      hasFavoriteBtn
      title="Recipient"
      type="dropoff"
      clearErrors={clearErrors}
      setValue={setValue}
      handleSelectInput={handleSelectInput}
    >
      <LabelContent isRequired direction="vertical" label="Address">
        <AddrAutoComplete
          hasError={!!errors.dropoff?.address}
          inputValue={inputValue}
          placeholder="Search or paste address"
          type="dropoff"
          watch={watch}
          resetAddrInfo={resetAddrInfo}
          handleAddrChange={handleAddrChange}
          handleAddrBlur={handleAddrBlur}
          handleAddrSelect={handleAddrSelect}
          handleAddrCopy={handleAddrCopy}
          handleSelectInput={handleSelectInput}
        />
        <Input
          maxLength={100}
          placeholder="Enter the detailed address (Optional)"
          register={register("dropoff.addressDetail")}
        />
        {errors.dropoff?.address?.message && (
          <ErrorMessage message={errors.dropoff.address.message} />
        )}
      </LabelContent>
      <LabelContent isRequired direction="vertical" label="Name">
        <Input
          hasError={!!errors.dropoff?.name}
          maxLength={100}
          placeholder="Enter contact name"
          register={register("dropoff.name", { required: ERROR_MSG.FIELD })}
        />
        {errors.dropoff?.name?.message && (
          <ErrorMessage message={errors.dropoff.name.message} />
        )}
      </LabelContent>
      <LabelContent
        isRequired
        direction="vertical"
        label="Mobile number"
        marginBottom={0}
      >
        <S.FlexWrapper>
          <CountryListDropdown
            countries={countries}
            selectedCountry={selectedCountry}
            handleSelectCountryWithCode={handleSelectCountryInputReset}
          />
          <S.PhoneInputWrapper>
            <S.SelectorCountryCode>
              +{selectedCountry.dial}
            </S.SelectorCountryCode>
            <Input
              css={S.phoneInput}
              hasError={!!errors.dropoff?.phone}
              maxLength={maxLengthPhoneNumber(selectedCountry)}
              placeholder="Enter mobile number"
              register={register("dropoff.phone", {
                required: ERROR_MSG.FIELD,
                maxLength: maxLengthPhoneNumber(selectedCountry),
                validate: (value) => value.length > 9 || ERROR_MSG.MOBILE_VALID,
                onChange: (e) => {
                  setValue(
                    "dropoff.phone",
                    removeFirstStringZero(onlyNumber(e.target.value)),
                  );
                },
              })}
            />
          </S.PhoneInputWrapper>
        </S.FlexWrapper>
        {errors.dropoff?.phone?.message && (
          <ErrorMessage message={errors.dropoff.phone.message} />
        )}
      </LabelContent>
    </Card>
  );
};

export default RecipientInfo;
