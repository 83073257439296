import { useRadioBtn } from "hooks";
import type { UseFormClearErrors } from "react-hook-form";

import { PICKUP_TYPES } from "assets";
import type { OrderForm } from "types";

const useTime = (clearErrors: UseFormClearErrors<OrderForm>) => {
  const { radioState: timeRadioState, handleClickRadioBtn } =
    useRadioBtn(PICKUP_TYPES);

  const handleClickTimeType =
    (key: (typeof PICKUP_TYPES)[number]["key"]) => () => {
      clearErrors("reqDatetime");

      handleClickRadioBtn(key)();
    };

  return {
    timeRadioState,
    handleClickTimeType,
  };
};

export default useTime;
