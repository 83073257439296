import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const card = css`
  width: 564px;

  & > div:first-of-type {
    margin-bottom: 24px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  height: 40px;
`;

export const PhoneInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectorCountryCode = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    position: absolute;
    top: 50%;
    left: 12px;
    color: ${theme.color.gray_90};
    transform: translateY(-50%);
  `}
`;

export const phoneInput = css`
  padding-left: 58px;
`;
