import type { CountryNumberListClientModel, KeyOf } from "types";

export const getCountryList = (
  countryCodeList: KeyOf<CountryNumberListClientModel>[],
  totalCountryList?: CountryNumberListClientModel,
) => {
  if (!totalCountryList) return {};

  const newCountryList: CountryNumberListClientModel = countryCodeList.reduce(
    (acc, value) => ({ ...acc, [value]: totalCountryList?.[value] }),
    {},
  );

  return newCountryList;
};

export const getFilterCountries = (
  countries: CountryNumberListClientModel,
  filterValues: string[],
) =>
  Object.values(countries)
    ?.filter((country) => !filterValues.includes(country?.code))
    ?.filter(Boolean);
