import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const card = css`
  width: 564px;

  & > div:first-of-type {
    margin-bottom: 24px;
  }
`;

export const DateWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 238px 164px;
    grid-column-gap: 12px;
    align-items: center;
  `}
`;

export const calendarInput = (theme: Theme) => css`
  width: 100%;

  & > input {
    border: 1px solid ${theme.color.gray_30};
  }
`;

export const TimeDropDownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const timeDropdown = (theme: Theme) => css`
  flex: 1;

  & > button {
    & > span {
      ${theme.font.medium_14};
    }

    & > svg {
      fill: ${theme.color.gray_60};
    }
  }

  & > ul {
    max-height: 200px;
  }
`;

export const colon = (theme: Theme) => css`
  & > circle {
    fill: ${theme.color.gray_60};
  }
`;

export const radioBtn = (theme: Theme) => css`
  width: 100%;
  padding: 10px 0;
  column-gap: 20px;

  & > div > label {
    ${theme.font.regular_14};
  }
`;
