import React from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegisterReturn } from "react-hook-form";

import type { Languages } from "types";
import * as S from "./Textarea.styled";

interface TextareaProps {
  className?: string;
  maxLength: number;
  placeholder: Languages;
  value: string;
  register?: UseFormRegisterReturn<string>;
}

const Textarea = ({
  className,
  maxLength,
  placeholder,
  value,
  register,
}: TextareaProps) => {
  const { t } = useTranslation();

  return (
    <S.TextareaWrapper className={className}>
      <S.Textarea
        maxLength={maxLength}
        placeholder={t(placeholder)}
        {...register}
      />
      <S.Length>{`${value?.length ?? 0}/${maxLength}`}</S.Length>
    </S.TextareaWrapper>
  );
};

export default Textarea;
