import React from "react";

import { CheckModal } from "components";
import { useModal } from "hooks";
import { LANGUAGE } from "assets";

interface LanguageCheckModalProps {
  selectedLanguage: keyof typeof LANGUAGE;
  handleSelectLanguage: (languageCode: string) => void;
}

const LanguageCheckModal = ({
  selectedLanguage,
  handleSelectLanguage,
}: LanguageCheckModalProps) => {
  const { modalRef, handleModalClose } = useModal();

  const handleLanguageCheck =
    (selectedLanguage: keyof typeof LANGUAGE) => (): void => {
      handleSelectLanguage(selectedLanguage);
      handleModalClose();
    };

  return (
    <CheckModal
      ref={modalRef}
      title="Change language?"
      desc="If you change the language, the content being filled in cannot be saved. Are you sure you want to change it?"
      activeBtnName="Confirm"
      closeBtnName="Cancel"
      activeFn={handleLanguageCheck(selectedLanguage)}
    />
  );
};

export default LanguageCheckModal;
