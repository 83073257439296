import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const HeaderWrapper = styled.header`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 14px 40px;
    background-color: ${theme.color.gray_20};
    z-index: ${theme.zIndex.HEADER};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 22px;
`;

export const LanguageWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    :first-of-type {
      path {
        fill: ${theme.color.gray_50};
      }
    }

    & > span {
      ${theme.font.regular_13};
      margin: 0 10px 0 6px;
      color: ${theme.color.gray_70};
    }
  `}
`;

export const dropdown = (theme: Theme) => css`
  min-width: 65px;
  width: max-content;
  margin-left: 6px;
  padding: 0;

  & > button {
    border: 0;
    padding: 0;

    &[aria-expanded="true"] {
      border: 0;
    }

    & > span {
      ${theme.font.regular_13};
      color: ${theme.color.gray_70};
    }

    & > svg {
      fill: ${theme.color.gray_50};
    }
  }

  & > ul {
    left: -30px;
    min-width: 120px;

    & > li {
      :not(:last-of-type) {
        border-bottom: 1px solid ${theme.color.gray_30};
      }

      & > button {
        ${theme.font.regular_13}
        padding: 0 8px 0 12px;
      }
    }
  }
`;

export const logoutBtn = (theme: Theme) => css`
  ${theme.font.regular_13};
  color: ${theme.color.gray_60};
  background-color: transparent;

  &::before {
    height: 0;
  }
`;

export const logoIcon = css`
  width: 85px;
`;
