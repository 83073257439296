import React from "react";

import Router from "router";
import { AuthProvider } from "contexts";
import { ErrorBoundary, IESupport, Modal } from "components";
import { browserInfo } from "utils/helper/browserName";

const Root = () => {
  return browserInfo() === "MS IE" ? (
    <IESupport />
  ) : (
    <AuthProvider>
      <ErrorBoundary>
        <Router>
          <Modal />
        </Router>
      </ErrorBoundary>
    </AuthProvider>
  );
};

export default Root;
