import React from "react";
import { useTranslation } from "react-i18next";
import type { UseFormWatch } from "react-hook-form";

import { Card } from "components";
import { commaWithCurrencyUnit, formatICTDateTime } from "utils";
import {
  PointIcon,
  TimeIcon,
  CargoIcon,
  MoneyIcon,
  ITEM_TYPE_LOOK_UP,
} from "assets";
import type { Languages, OrderForm } from "types";
import SummarySkeleton from "./SummarySkeleton";
import * as S from "./Summary.styled";

interface SummaryProps {
  isEstimationLoading: boolean;
  timeType: string;
  watch: UseFormWatch<OrderForm>;
}

const Summary = ({ isEstimationLoading, timeType, watch }: SummaryProps) => {
  const { t } = useTranslation();

  const total =
    watch("payment.price") -
    (watch("payment.levelDiscount") ?? 0) -
    (watch("payment.couponDiscount") ?? 0) +
    (watch("summary.insuranceFee") ?? 0);

  return (
    <Card css={S.Card} title="Summary">
      <S.Wrapper>
        <S.IconWrapper>
          <PointIcon />
        </S.IconWrapper>
        <S.ColumnWrapper>
          <S.Address>
            {watch("pickup.place") ? watch("pickup.place") : "-"}
          </S.Address>
          <S.AddressDetail>
            {!watch("pickup.addressDetail") && !watch("pickup.address") && "-"}
            {watch("pickup.addressDetail") &&
              `(${watch("pickup.addressDetail")}) `}
            {watch("pickup.address") && watch("pickup.address")}
          </S.AddressDetail>
        </S.ColumnWrapper>
      </S.Wrapper>
      <S.Wrapper>
        <S.PointIconWrapper>
          <PointIcon />
        </S.PointIconWrapper>
        <S.ColumnWrapper>
          <S.Address>
            {watch("dropoff.place") ? watch("dropoff.place") : "-"}
          </S.Address>
          <S.AddressDetail>
            {!watch("dropoff.addressDetail") &&
              !watch("dropoff.address") &&
              "-"}
            {watch("dropoff.addressDetail") &&
              `(${watch("dropoff.addressDetail")}) `}
            {watch("dropoff.address") && watch("dropoff.address")}
          </S.AddressDetail>
        </S.ColumnWrapper>
      </S.Wrapper>
      <S.Wrapper>
        <S.IconWrapper>
          <TimeIcon />
        </S.IconWrapper>
        <S.Time
          hasReqDatetime={!!watch("reqDatetime") && timeType !== "shippingNow"}
        >
          {timeType === "shippingNow"
            ? t(
                `“Shipping now“ is the driver can pickup at least 30 minutes from now.`,
              )
            : watch("reqDatetime")
            ? formatICTDateTime(watch("reqDatetime"), "DD/MM/YYYY HH:mm")
            : "-"}
        </S.Time>
      </S.Wrapper>
      <S.Wrapper>
        <S.IconWrapper>
          <CargoIcon />
        </S.IconWrapper>
        <S.ItemWrapper>
          <S.ItemType>
            {watch("item.itemType") === null
              ? "-"
              : t(ITEM_TYPE_LOOK_UP[watch("item.itemType")!] as Languages)}
          </S.ItemType>
          {!!watch("item.name") && (
            <S.ItemName>{watch("item.name")}</S.ItemName>
          )}
          {watch("item.weight") && <S.Item>{watch("item.weight")}kg</S.Item>}
        </S.ItemWrapper>
      </S.Wrapper>
      <S.Wrapper>
        <S.IconWrapper>
          <MoneyIcon />
        </S.IconWrapper>
        <S.MoneyWrapper>
          {isEstimationLoading ? (
            <SummarySkeleton />
          ) : (
            <>
              <S.MoneyRow>
                <div>{t("Price")}</div>
                <div>
                  {commaWithCurrencyUnit({
                    price: watch("payment.price"),
                    currencyUnit: "₭",
                    showPlusSign: false,
                  })}
                </div>
              </S.MoneyRow>
              <S.MoneyRow>
                <div>{t("Membership")}</div>
                <div>
                  {commaWithCurrencyUnit({
                    price: watch("payment.levelDiscount"),
                    currencyUnit: "₭",
                    showPlusSign: false,
                    showMinusSign: true,
                  })}
                </div>
              </S.MoneyRow>
              <S.MoneyRow>
                <div>{t("Coupon")}</div>
                <div>
                  {commaWithCurrencyUnit({
                    price: watch("payment.couponDiscount"),
                    currencyUnit: "₭",
                    showPlusSign: false,
                    showMinusSign: true,
                  })}
                </div>
              </S.MoneyRow>
              <S.TotalWrapper>
                <div>{t("Total")}</div>
                <div>
                  {commaWithCurrencyUnit({
                    price: total,
                    currencyUnit: "₭",
                    showPlusSign: false,
                  })}
                </div>
              </S.TotalWrapper>
            </>
          )}
        </S.MoneyWrapper>
      </S.Wrapper>
    </Card>
  );
};

export default Summary;
