import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const card = css`
  width: 564px;

  & > div:first-of-type {
    margin-bottom: 24px;
  }
`;

export const ItemCautionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CautionsAgreement = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    & > span {
      ${theme.font.regular_12};
      color: ${theme.color.gray_60};
    }
  `}
`;

export const checkCircleIcon = (theme: Theme) => css`
  width: 20px;
  height: 20px;

  & > g > path {
    fill: ${theme.color.green_10};
  }
`;

export const Description = styled.span<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    ${theme.font.regular_12};
    display: flex;
    column-gap: 4px;
    color: ${hasError ? theme.color.red_20 : theme.color.gray_60};
  `}
`;

export const link = (theme: Theme) => css`
  ${theme.font.regular_12};
  justify-content: flex-start;
`;

export const totalWeight = css`
  width: 152px;
`;

export const note = css`
  height: 110px;
`;

export const descWeight = css`
  flex-direction: column;
`;
