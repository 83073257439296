import type { UseFormSetValue } from "react-hook-form";

import type { OrderForm, PostEstimationServerModel } from "types";

const useCoupon = (setValue: UseFormSetValue<OrderForm>) => {
  const saveEstimatedAmount = (amount: PostEstimationServerModel) => {
    setValue("payment.price", amount.estimatedPrice);
    setValue("payment.levelDiscount", amount.levelDiscount);
    setValue("payment.couponDiscount", amount.couponDiscount);
    setValue("summary.distance", amount.distance);
    setValue("summary.insuranceFee", amount.insuranceFee);
  };

  const resetSelectCoupon = () => {
    setValue("payment.couponCode", "");
    setValue("payment.couponDiscount", 0);
  };

  return {
    saveEstimatedAmount,
    resetSelectCoupon,
  };
};

export default useCoupon;
