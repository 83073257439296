import { useState } from "react";
import { geocodeByPlaceId } from "react-places-autocomplete";

const useGeocodeAddr = () => {
  const [geoCode, setGeoCode] = useState<google.maps.GeocoderResult | null>(
    null,
  );

  const getGeocode = async (placeId: string | null) => {
    if (!placeId) return;

    try {
      const geocode = await geocodeByPlaceId(placeId);

      if (geocode) {
        setGeoCode(geocode[0]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    geoCode,
    getGeocode,
  };
};

export default useGeocodeAddr;
