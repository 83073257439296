import { useState, useRef, useEffect } from "react";

import { useKeyClose, useKeyTrap, useOnClickOutside } from "hooks";

const useDropdown = (
  selectedValue: string,
  handleSelect?: (value: string) => void,
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void,
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void,
) => {
  const dropdownRef = useRef(null);
  const optionsRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleClickOption = (option: string) => (): void => {
    if (handleSelect && option !== selectedValue) {
      handleSelect(option);
    }

    handleClose();
  };

  const handleOpener = (): void => {
    isOpen ? handleClose() : handleOpen();
  };

  useOnClickOutside(dropdownRef, handleClose);
  useKeyTrap(optionsRef, handleClose);
  useKeyClose(optionsRef, handleClose);

  useEffect(() => {
    if (isOpen) {
      handleFocusCondition && handleFocusCondition();
    } else {
      handleBlurCondition && handleBlurCondition();
    }
  }, [isOpen]);

  return {
    dropdownRef,
    optionsRef,
    isOpen,
    handleOpener,
    handleClickOption,
  };
};

export default useDropdown;
