import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";

import * as S from "./Summary.styled";

const SummarySkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.MoneyRow>
        <div>{t("Price")}</div>
        <Skeleton width="83px" height="18px" />
      </S.MoneyRow>
      <S.MoneyRow>
        <div>{t("Membership")}</div>
        <Skeleton width="62px" height="18px" />
      </S.MoneyRow>
      <S.MoneyRow>
        <div>{t("Coupon")}</div>
        <Skeleton width="62px" height="18px" />
      </S.MoneyRow>
      <S.TotalWrapper>
        <div>{t("Total")}</div>
        <Skeleton width="104px" height="22px" />
      </S.TotalWrapper>
    </>
  );
};

export default SummarySkeleton;
