import styled from "@emotion/styled";
import { css } from "@emotion/react";

const mixInFlex = css`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const IESupport = styled.section`
  ${({ theme }) => css`
    ${mixInFlex};
    width: 100%;
    height: 100vh;
    background-color: #fff; // TODO: 각 프로젝트에 맞는 디자인 시스템으로 수정 필요

    & > h1 {
      ${theme.a11y.visuallyHidden}
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${mixInFlex};
`;

export const ContentItem = styled.dl`
  ${mixInFlex};
  margin: 10px 0 80px;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 6px;
    color: #343e4d; // TODO: 각 프로젝트에 맞는 디자인 시스템으로 수정 필요
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: #657080; // TODO: 각 프로젝트에 맞는 디자인 시스템으로 수정 필요
    text-align: center;
    white-space: pre-line;
  `}
`;

export const BrowserWrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
  column-gap: 72px;
`;

export const BrowserItem = styled.div`
  ${({ theme }) => css`
    ${mixInFlex}
    row-gap: 20px;

    & > span {
      ${theme.font.medium_13};
      color: #4e5968; // TODO: 각 프로젝트에 맞는 디자인 시스템으로 수정 필요
    }
  `}
`;
