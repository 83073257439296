import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "contexts";
import { PublicLayout } from "components";
import { useGoogleAnalytics } from "hooks";
import { PATH } from "constants/index";

const PublicRoute = () => {
  const location = useLocation();

  const { user, initializing } = useAuth();
  useGoogleAnalytics();

  if (initializing) return null;

  return user ? (
    <Navigate to={PATH.order} state={{ from: location }} replace />
  ) : (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
