import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "contexts";
import { PrivateLayout } from "components";
import { useGoogleAnalytics } from "hooks";

const PrivateRoute = () => {
  const { pathname, search } = useLocation();

  const { user, initializing } = useAuth();
  useGoogleAnalytics();

  if (initializing) return null;

  return user ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to="/" state={{ from: pathname + search }} replace />
  );
};

export default PrivateRoute;
