import React, { useId } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";
import * as S from "./RadioBtn.styled";

export type RadioStyleType = "radio" | "button";

interface RadioBtnProps<
  T extends readonly { key: T[number]["key"] & string; label: Languages }[],
> {
  className?: string;
  isDisabled?: boolean;
  currentValue: T[number]["key"];
  icon?: React.ReactNode;
  radioList: T;
  styleType?: RadioStyleType;
  handleClickRadioBtn?: (key: T[number]["key"]) => () => void;
  register?: UseFormRegisterReturn<string>;
}

const RadioBtn = <
  T extends readonly { key: T[number]["key"] & string; label: Languages }[],
>({
  className,
  isDisabled,
  currentValue,
  icon,
  radioList,
  styleType = "radio",
  handleClickRadioBtn,
  register,
}: RadioBtnProps<T>) => {
  const { t } = useTranslation();
  const uuid = useId();

  return (
    <S.RadioWrapper className={className}>
      {radioList.map((radio) => {
        const isChecked = currentValue === radio.key;

        return (
          <S.Label
            key={radio.key + uuid}
            htmlFor={radio.key + uuid}
            isChecked={isChecked}
            styleType={styleType}
          >
            <input
              type="radio"
              id={radio.key + uuid}
              name={uuid}
              checked={isChecked}
              disabled={isDisabled}
              value={radio.key}
              onChange={handleClickRadioBtn && handleClickRadioBtn(radio.key)}
              {...register}
            />
            <S.RadioBtn htmlFor={radio.key + uuid} />
            <span>{t(radio.label)}</span>
            {icon && icon}
          </S.Label>
        );
      })}
    </S.RadioWrapper>
  );
};

export default RadioBtn;
