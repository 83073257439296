export const HOUR_OPTIONS = [
  { key: "00", label: "00" },
  { key: "01", label: "01" },
  { key: "02", label: "02" },
  { key: "03", label: "03" },
  { key: "04", label: "04" },
  { key: "05", label: "05" },
  { key: "06", label: "06" },
  { key: "07", label: "07" },
  { key: "08", label: "08" },
  { key: "09", label: "09" },
  { key: "10", label: "10" },
  { key: "11", label: "11" },
  { key: "12", label: "12" },
  { key: "13", label: "13" },
  { key: "14", label: "14" },
  { key: "15", label: "15" },
  { key: "16", label: "16" },
  { key: "17", label: "17" },
  { key: "18", label: "18" },
  { key: "19", label: "19" },
  { key: "20", label: "20" },
  { key: "21", label: "21" },
  { key: "22", label: "22" },
  { key: "23", label: "23" },
] as const;

export const MINUTE_OPTIONS = [
  { key: "00", label: "00" },
  { key: "15", label: "15" },
  { key: "30", label: "30" },
  { key: "45", label: "45" },
] as const;

export const PAID_BY_OPTIONS = [
  { key: "pickup", label: "Sender" },
  { key: "dropoff", label: "Recipient" },
] as const;
