import axios from "axios";

import { getS3FileFormData } from "utils";
import type {
  CountryNumberListServerModel,
  GetCouponQueryModel,
  GetCouponServerModel,
  GetFavoriteQueryModel,
  GetFavoriteServerModel,
  ItemTypeServerModel,
  S3PresignedServerModel,
} from "types";
import { ax } from "./axios";

export const postS3PresignedAPI = async (
  key: string,
  file: File | Blob,
): Promise<any> => {
  const res: S3PresignedServerModel = await ax.post(`/presigned/${key}`);

  const formData = getS3FileFormData(res, file);

  return axios.post(res.data.url, formData);
};

export const getCountryNumberList = async () => {
  const { data } = await ax.get<CountryNumberListServerModel>("/countries");

  return data;
};

export const getItemType = async () => {
  const { data } = await ax.get<ItemTypeServerModel>("/items");

  return data.items;
};

export const getLocationCoordinate = async (
  lat: number,
  lng: number,
  language: string,
) => {
  if (!lat || !lng) return null;
  const { data } = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GEOCODING_GOOGLEMAP_KEY}&latlng=${lat},${lng}&language=${language}`,
  );

  return data;
};

export const getFavoritesAPI = async (req: GetFavoriteQueryModel) => {
  const { data } = await ax.get<GetFavoriteServerModel>("/favorites", {
    params: req.query,
  });

  return data;
};

export const getOriginUrl = async (url: string) => {
  if (!url) return null;

  try {
    const { data } = await axios.get(
      process.env.REACT_APP_SCRIPT_ADDRCOPY_URL,
      { params: { url } },
    );

    return data;
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const getCouponListAPI = async (req: GetCouponQueryModel) => {
  const { data } = await ax.get<GetCouponServerModel>("/clients/coupons", {
    params: req.query,
  });

  return data;
};
