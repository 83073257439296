import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Order = styled.section`
  position: relative;
  display: flex;
  column-gap: 32px;
  width: 957px;
  margin: 0 auto 100px auto;
  padding-top: 104px;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 32px;
`;

export const card = css`
  width: 564px;
`;

export const RightWrapper = styled.div`
  position: sticky;
  top: 104px;
  display: flex;
  flex-flow: column;
  row-gap: 16px;
  height: fit-content;
  margin-bottom: 100px;
`;

export const summaryCard = css`
  width: 364px;
`;
