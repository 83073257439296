import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "components";
import { useModal } from "hooks";
import { NewIcon } from "assets";
import * as S from "./NewAccountModal.styled";

interface NewAccountModalProps {
  phoneNumber: string;
}

const NewAccountModal = ({ phoneNumber }: NewAccountModalProps) => {
  const { t } = useTranslation();
  const { modalRef } = useModal();

  return (
    <ConfirmModal css={S.confirmModal} activeBtnName="Confirm" ref={modalRef}>
      <NewIcon />
      <S.Title>{t("You seem to be new!")}</S.Title>
      <S.Desc>
        0{phoneNumber}{" "}
        {t(
          "has not been registered on KOKKOK Move. Let’s create a new account for you.",
        )}
      </S.Desc>
      <S.AddDesc>
        {t("*You can join as a member through the KOKKOK Move app.")}
      </S.AddDesc>
    </ConfirmModal>
  );
};

export default NewAccountModal;
