import dayjs, { Dayjs } from "dayjs";

type DateArgs = Dayjs | string | number | Date;

export const onlyNumber = (string: string) => {
  if (!string) return "";
  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  return string.replace(regExp, "");
};

export const numberCommaParse = (cost: number) => {
  return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatTwoDigitsTime = (time: number) => {
  return time < 10 ? `0${time}` : `${time}`;
};

export const formatCompareMinute = (minute: string) => {
  if (minute >= "00" && minute < "15") {
    return "15";
  }
  if (minute >= "15" && minute < "30") {
    return "30";
  }
  if (minute >= "30" && minute < "45") {
    return "45";
  }
  return "00";
};

export const getPickupTime = () => dayjs().add(30, "m");

// NOTE: 현재 시간과 쿠폰 만료 시간의 차이가 하루 이내인지 체크하는 함수
export const isCouponExpiredSoon = (couponDateTime: string) =>
  dayjs.utc(couponDateTime).diff(dayjs.utc(), "h", true) < 24;

export const removeFirstStringZero = (phone: string) =>
  phone.startsWith("0") ? phone.slice(1) : phone;

export const formatICTDateTime = (date: DateArgs, template?: string) =>
  dayjs.utc(date).tz("Asia/Vientiane").format(template);

// NOTE: (ex: 11월 29일 00시 (+0900) -> 11월 29일 00시 (+0700))
export const formatICTStandardDateTime = (date: DateArgs, template?: string) =>
  dayjs.tz(date, "Asia/Vientiane").format(template);

export const comma = (str: string | number): string => {
  const initString = typeof str !== "string" ? `${str}` : str;

  if (!initString) return "";

  return `${initString.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}`;
};

interface CommaWithCurrencyUnitProps {
  price: string | number | null | undefined;
  currencyUnit: string;
  showPlusSign: boolean;
  showMinusSign?: boolean;
}

export const commaWithCurrencyUnit = ({
  price,
  currencyUnit,
  showPlusSign,
  showMinusSign,
}: CommaWithCurrencyUnitProps): string => {
  const initPrice = typeof price === "number" ? `${price}` : price;

  if (!initPrice) return "₭ 0";

  if (+initPrice < 0) {
    return `- ${currencyUnit} ${comma(Math.abs(+initPrice))}`;
  } else {
    if (showMinusSign) return `- ${currencyUnit}  ${comma(+initPrice)}`;

    return showPlusSign
      ? `+ ${currencyUnit} ${comma(+initPrice)}`
      : `${currencyUnit} ${comma(+initPrice)}`;
  }
};
