import React, { useState } from "react";
import { isEmpty } from "lodash-es";
import type { UseFormRegister, UseFormSetValue } from "react-hook-form";

import { compressedFile } from "utils";
import { CameraIcon } from "assets";
import type { OrderForm, Package } from "types";
import * as S from "./PreviewImg.styled";

interface PreviewImgProps {
  hasError: boolean;
  imgFile: Package["photo"];
  register: UseFormRegister<OrderForm>;
  setValue: UseFormSetValue<OrderForm>;
}

const PreviewImg = ({
  hasError,
  imgFile,
  register,
  setValue,
}: PreviewImgProps) => {
  const [url, setUrl] = useState("");

  const handleImgChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (!e.target.files) return;

    const image = e.target.files[0];
    const compressedImg = await compressedFile(image);

    setValue("item.photo", compressedImg);
    setUrl(URL.createObjectURL(await compressedFile(compressedImg)));
  };

  return !isEmpty(imgFile) && url ? (
    <S.PreviewImg htmlFor="img">
      <input
        id="img"
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        {...register("item.photo", {
          onChange: handleImgChange,
        })}
      />
      <img src={url} alt="previewImg" />
    </S.PreviewImg>
  ) : (
    <S.EmptyImg hasError={hasError} htmlFor="img">
      <input
        id="img"
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        {...register("item.photo", {
          onChange: handleImgChange,
        })}
      />
      <CameraIcon />
    </S.EmptyImg>
  );
};

export default PreviewImg;
