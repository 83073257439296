import React from "react";
import { useTranslation } from "react-i18next";
import type { UseFormWatch } from "react-hook-form";

import { SearchLocModal } from "components";
import { useModal } from "hooks";
import { LocationIcon, SearchIcon } from "assets";
import type {
  AddrAutoCompleteInfo,
  ExtractCopiedUrl,
  ExtractType,
  Languages,
  OrderForm,
} from "types";
import { useAddrAutoComplete } from "./hooks";
import * as S from "./AddrAutoComplete.styled";

interface AddrAutoCompleteProps {
  inputValue: string;
  hasError?: boolean;
  type: "pickup" | "dropoff";
  placeholder: Languages;
  watch: UseFormWatch<OrderForm>;
  resetAddrInfo: () => void;
  handleAddrChange: () => void;
  handleAddrBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleAddrSelect: (value: AddrAutoCompleteInfo) => void;
  handleAddrCopy: (
    extractType: ExtractType,
    data: ExtractCopiedUrl["data"],
  ) => void;
  handleSelectInput: (value: string) => void;
}

const AddrAutoComplete = ({
  inputValue,
  hasError,
  type,
  placeholder,
  watch,
  resetAddrInfo,
  handleAddrChange,
  handleAddrBlur,
  handleAddrSelect,
  handleAddrCopy,
  handleSelectInput,
}: AddrAutoCompleteProps) => {
  const { t } = useTranslation();

  const { handleModalOpen, modalRef } = useModal();
  const {
    inputRef,
    addrSuggestions,
    handleMouseDown,
    handleAutoCompleteKeyPress,
    handleAutoCompleteBlur,
    handleAutoCompleteChange,
    handleAutoCompleteFocus,
    handleAutoCompleteSelect,
    handleAutoCompleteSearch,
  } = useAddrAutoComplete(
    inputValue,
    type,
    resetAddrInfo,
    handleAddrChange,
    handleAddrBlur,
    handleAddrSelect,
    handleAddrCopy,
    handleSelectInput,
  );

  return (
    <S.AddrAutoComplete>
      <S.AddressWrapper>
        <S.AutocompleteInput
          ref={inputRef}
          autoComplete="off"
          isDropdownOpen={!!addrSuggestions.length}
          hasError={!!hasError}
          value={inputValue}
          placeholder={t(placeholder)}
          onChange={handleAutoCompleteChange}
          onBlur={handleAutoCompleteBlur}
          onFocus={handleAutoCompleteFocus}
          onKeyDown={handleAutoCompleteKeyPress}
        />
        <S.SearchButton type="button" onClick={handleAutoCompleteSearch}>
          <SearchIcon />
        </S.SearchButton>
        <S.OpenMapButton
          type="button"
          onClick={handleModalOpen(
            "SearchLocationModal",
            <SearchLocModal
              ref={modalRef}
              type={type}
              watch={watch}
              handleInput={handleSelectInput}
              handleAddrSelect={handleAddrSelect}
            />,
          )}
        >
          <LocationIcon css={S.locationIcon} />
        </S.OpenMapButton>
      </S.AddressWrapper>
      <S.DropdownWrapper isActive={addrSuggestions.length !== 0}>
        {addrSuggestions.map((suggestion) => (
          <li key={suggestion.place_id}>
            <S.DropdownButton
              type="button"
              onMouseDown={handleMouseDown}
              onClick={handleAutoCompleteSelect(suggestion)}
            >
              {suggestion.description}
            </S.DropdownButton>
          </li>
        ))}
      </S.DropdownWrapper>
    </S.AddrAutoComplete>
  );
};

export default React.memo(AddrAutoComplete);
