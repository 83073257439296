import React, { useEffect, useRef, useState } from "react";
import { UseFormWatch, useForm } from "react-hook-form";

import { DetailModal } from "components";
import { useLocationMarker, useGoogleMap, useModal } from "hooks";
import { useGetLocationCoordinate } from "services";
import { getLanguageCode } from "utils";
import { DropoffIcon, PickupIcon } from "assets";
import { DEFAULT_PICKUP_COORD } from "constants/index";
import type { AddrAutoCompleteInfo, OrderForm } from "types";
import * as S from "./SearchLocModal.styled";

interface PointModalProps {
  className?: string;
  type: "pickup" | "dropoff";
  watch: UseFormWatch<OrderForm>;
  handleInput: (value: string) => void;
  handleAddrSelect: (value: AddrAutoCompleteInfo) => void;
}

const SearchLocModal = React.forwardRef<HTMLDialogElement, PointModalProps>(
  ({ className, type, watch, handleInput, handleAddrSelect }, ref) => {
    const isMounted = useRef(false);

    const { watch: modalWatch, setValue } = useForm<{
      [key: string]: AddrAutoCompleteInfo;
    }>({
      mode: "all",
      defaultValues: {
        [type]: {
          place: watch(type).place,
          address: watch(type).address,
          coord: {
            x: watch(type).coord.x,
            y: watch(type).coord.y,
          },
          placeId: watch(type).placeId,
        },
      },
    });

    const { handleModalClose } = useModal();
    const [coord, setCoord] = useState<any>({
      [type]: {
        position: {
          lng: watch(type).coord.x || DEFAULT_PICKUP_COORD.lng,
          lat: watch(type).coord.y || DEFAULT_PICKUP_COORD.lat,
        },
        type,
      },
    });

    const languageCode = getLanguageCode();
    const { data: locationCoordinate, refetch } = useGetLocationCoordinate(
      coord[type].position.lat,
      coord[type].position.lng,
      languageCode,
      { enabled: !watch(`${type}.address`) },
    );

    const handleMapClick = (e: any) => {
      const clickedLocation = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      const newLocation = {
        [type]: {
          position: clickedLocation,
          type,
        },
      };

      setCoord(newLocation);
    };

    const handleConfirm = () => {
      const addrAutoCompleteInfo = {
        address: modalWatch(`${type}.address`),
        place: modalWatch(`${type}.place`),
        placeId: modalWatch(`${type}.placeId`),
        coord: {
          x: coord[type].position.lng,
          y: coord[type].position.lat,
        },
      };

      handleInput(modalWatch(`${type}.address`));
      handleAddrSelect(addrAutoCompleteInfo);
      handleModalClose();
    };

    const { ref: mapRef, googleMap } = useGoogleMap(
      watch(type).coord.x,
      watch(type).coord.y,
      handleMapClick,
    );
    useLocationMarker(googleMap!, coord!);

    useEffect(() => {
      if (locationCoordinate?.status === "OK") {
        const addr = locationCoordinate.results[0].formatted_address;
        const place =
          locationCoordinate.results[0].address_components[0].long_name;
        const placeId = locationCoordinate.results[0].place_id;

        setValue(`${type}.address`, addr);
        setValue(`${type}.place`, place);
        setValue(`${type}.placeId`, placeId);
      }
    }, [locationCoordinate]);

    useEffect(() => {
      if (isMounted.current) {
        refetch();
      }
      isMounted.current = true;
    }, [coord]);

    return (
      <DetailModal
        className={className}
        css={S.searchLocModal}
        ref={ref}
        title="Choose from map"
        posBtnName="Confirm"
        posFn={handleConfirm}
      >
        <S.LocInfoWrapper>
          {type === "pickup" ? <PickupIcon /> : <DropoffIcon />}
          <S.Location>{modalWatch(type).address}</S.Location>
          <S.Address>{modalWatch(type).place}</S.Address>
        </S.LocInfoWrapper>
        <S.Map ref={mapRef} />
      </DetailModal>
    );
  },
);

SearchLocModal.displayName = "SearchLocModal";

export default SearchLocModal;
