import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const modal = css`
  padding-top: 32px;
`;

export const Title = styled.span`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 32px;
    color: ${theme.color.gray_90};
  `}
`;

const restriction = css`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: 100%;
`;

export const Prohibition = styled.div`
  ${restriction};
  margin-bottom: 24px;
`;

export const Restriction = styled.div`
  ${restriction};
  margin-bottom: 32px;
`;

export const RestrictionTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 4px 12px;
    background-color: ${theme.color.gray_20};

    & > img {
      width: 16px;
      height: 16px;
    }

    & > span {
      ${theme.font.medium_16};
      color: ${theme.color.gray_90};
    }
  `}
`;

export const RestrictedItemWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const RestrictedItem = styled.li`
  ${({ theme }) => css`
    ${theme.font.regular_15};
    display: grid;
    grid-template-columns: 20px 1fr;
    color: ${theme.color.gray_70};
    white-space: pre-wrap;

    &::before {
      content: "•";
      text-align: center;
    }
  `}
`;
