import styled from "@emotion/styled";
import { css } from "@emotion/react";

const INPUT_UNIT_GAP = 8;
const UNIT_PADDING_RIGHT = 12;

export const InputWrapper = styled.div`
  position: relative;
`;

export const input = (interval: number) => css`
  padding-right: ${INPUT_UNIT_GAP + UNIT_PADDING_RIGHT + interval}px;
`;

export const Unit = styled.label`
  ${({ theme }) => css`
    ${theme.font.semibold_14};
    position: absolute;
    top: 50%;
    right: ${UNIT_PADDING_RIGHT}px;
    color: ${theme.color.gray_90};
    transform: translateY(-50%);
  `}
`;
