import React, { useEffect } from "react";
import dayjs from "dayjs";
import type {
  FieldError,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";

import {
  Card,
  CalendarInput,
  Dropdown,
  ErrorMessage,
  LabelContent,
  RadioBtn,
} from "components";
import { useDropdownValue, usePickupTime } from "hooks";
import { formatICTStandardDateTime } from "utils";
import { DotIcon, HOUR_OPTIONS, MINUTE_OPTIONS, PICKUP_TYPES } from "assets";
import type { Languages, OrderForm } from "types";
import { useChangeCalendar } from "./hooks";
import * as S from "./Time.styled";

interface TimeProps {
  radioState: (typeof PICKUP_TYPES)[number]["key"];
  error?: FieldError;
  clearErrors: UseFormClearErrors<OrderForm>;
  setValue: UseFormSetValue<OrderForm>;
  handleClickRadioBtn: (
    key: (typeof PICKUP_TYPES)[number]["key"],
  ) => () => void;
}

const Time = ({
  error,
  radioState,
  clearErrors,
  setValue,
  handleClickRadioBtn,
}: TimeProps) => {
  const { today, leastPickupHour, leastPickupMinute } = usePickupTime();
  const { selectedOption: selectedHourOption, handleSelect: handleHourSelect } =
    useDropdownValue(HOUR_OPTIONS);
  const {
    selectedOption: selectedMinuteOption,
    handleSelect: handleMinuteSelect,
  } = useDropdownValue(MINUTE_OPTIONS);

  const { selectedDates, selectedDate, handleChangeDate } = useChangeCalendar();
  const isTodaySelected = today.format("DD/MM/YYYY") === selectedDate;

  const isHourDisplayed = (key: string) =>
    !(isTodaySelected && +leastPickupHour > +key);

  const isMinDisplayed = (key: string) =>
    !(
      +selectedHourOption.key === +leastPickupHour && +leastPickupMinute > +key
    );

  const handleClickRadioBtnSet =
    (key: (typeof PICKUP_TYPES)[number]["key"]) => () => {
      handleClickRadioBtn(key)();

      if (key === "scheculeForLater") {
        setValue("reqDatetime", "");
      }
    };

  const handleChangeDateSelect = (date: dayjs.Dayjs[] | []) => {
    handleChangeDate(date);

    if (today.format("DD/MM/YYYY") === date[0].format("DD/MM/YYYY")) {
      handleHourSelect(leastPickupHour);
      handleMinuteSelect(leastPickupMinute);
    }
  };

  const handleChangeHourSelect = (key: string) => {
    handleHourSelect(key);

    if (isTodaySelected && key === leastPickupHour) {
      handleMinuteSelect(leastPickupMinute);
    }
  };

  useEffect(() => {
    if (
      radioState === "scheculeForLater" &&
      selectedDate &&
      selectedHourOption.key &&
      selectedMinuteOption.key
    ) {
      setValue(
        "reqDatetime",
        formatICTStandardDateTime(
          dayjs(selectedDate, "DD/MM/YYYY")
            .set("h", +selectedHourOption.key)
            .set("m", +selectedMinuteOption.key)
            .format("YYYY-MM-DDTHH:mm:ss"),
        ),
      );
      clearErrors("reqDatetime");
    }
  }, [
    radioState,
    selectedDate,
    selectedHourOption.key,
    selectedMinuteOption.key,
  ]);

  return (
    <Card css={S.card} title="Time">
      <LabelContent
        isRequired
        direction="vertical"
        label="Pick-up time"
        marginBottom={0}
      >
        <RadioBtn
          css={S.radioBtn}
          currentValue={radioState}
          radioList={PICKUP_TYPES}
          handleClickRadioBtn={handleClickRadioBtnSet}
        />
        {radioState === "scheculeForLater" && (
          <S.DateWrapper>
            <CalendarInput
              css={S.calendarInput}
              hasError={!!error?.message}
              selectedDate={selectedDates}
              type="date"
              value={selectedDate}
              handleChangeDate={handleChangeDateSelect}
            />
            <S.TimeDropDownWrapper>
              <Dropdown
                css={S.timeDropdown}
                isDisplayed={isHourDisplayed}
                hasError={!!error?.message}
                options={HOUR_OPTIONS}
                placeholder={"00" as Languages}
                selectedOption={selectedHourOption}
                handleSelect={handleChangeHourSelect}
              />
              <DotIcon css={S.colon} />
              <Dropdown
                css={S.timeDropdown}
                isDisplayed={isMinDisplayed}
                hasError={!!error?.message}
                options={MINUTE_OPTIONS}
                placeholder={"00" as Languages}
                selectedOption={selectedMinuteOption}
                handleSelect={handleMinuteSelect}
              />
            </S.TimeDropDownWrapper>
          </S.DateWrapper>
        )}
        {error?.message && <ErrorMessage message={error.message} />}
      </LabelContent>
    </Card>
  );
};

export default Time;
