import { useState } from "react";

import { useGetLocationCoordinate } from "services";
import { getLanguageCode } from "utils";

const useCoordAddr = (type: "pickup" | "dropoff") => {
  const initCoord = {
    [type]: {
      coord: { x: 0, y: 0 },
      type,
    },
  };

  const [coord, setCoord] = useState<typeof initCoord>(initCoord);
  const languageCode = getLanguageCode();

  const { data: locationCoordinate, refetch: refetchLocationCoordinate } =
    useGetLocationCoordinate(
      coord[type].coord.y,
      coord[type].coord.x,
      languageCode,
      { enabled: !!coord[type].coord.x && !!coord[type].coord.y },
    );

  const handleCoordSet = (data: typeof initCoord) => {
    setCoord(data);
  };

  return {
    initCoord,
    locationCoordinate,
    refetchLocationCoordinate,
    handleCoordSet,
  };
};

export default useCoordAddr;
