import { PAYMENT_METHOD } from "constants/index";

export const ORDER_FORM = {
  shipmentType: "kokkok",
  reqDatetime: "",
  pickup: {
    place: "",
    address: "",
    addressDetail: "",
    coord: {
      x: 0,
      y: 0,
    },
    placeId: "",
    countryCode: "LA",
    countryDial: "856",
    phone: "",
    name: "",
  },
  dropoff: {
    place: "",
    address: "",
    addressDetail: "",
    coord: {
      x: 0,
      y: 0,
    },
    placeId: "",
    countryCode: "LA",
    countryDial: "856",
    phone: "",
    name: "",
  },
  item: {
    itemType: null,
    name: "",
    weight: "",
    memo: "",
    photo: undefined,
  },
  payment: {
    paymentMethod: PAYMENT_METHOD,
    paidTime: { key: "", label: "" },
    currency: "LAK",
    price: 0,
    levelDiscount: 0,
    couponDiscount: 0,
    couponCode: "",
  },
  summary: {
    distance: 0,
    insuranceFee: null,
  },
} as const;
