import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface RootProps {
  isToday: boolean;
  isThisMonth: boolean;
}

export const Root = styled.li<RootProps>`
  ${({ isToday, isThisMonth, theme }) => css`
    width: 40px;
    height: 40px;
    border: ${isToday && `1px solid ${theme.color.black}`};
    border-radius: ${isToday && "50%"};

    & > button {
      color: ${!isThisMonth && theme.color.gray_50};
    }

    &[aria-selected="true"] {
      border-radius: 50%;
      background-color: ${theme.color.orange_20};

      & > button {
        color: ${theme.color.gray_00};
      }
    }

    &[aria-selected="false"] {
      &:not([disabled]):hover {
        border-radius: 50%;
        background-color: ${isToday
          ? theme.color.gray_10
          : theme.color.orange_10};
      }
    }
  `}
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 100%;
    background-color: transparent;

    color: ${theme.color.black};
    z-index: ${theme.zIndex.CALENDAR};

    &[disabled] {
      color: ${theme.color.gray_50};
    }
  `}
`;
