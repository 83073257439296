import type {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { ERROR_MSG } from "constants/index";
import type {
  AddrAutoCompleteInfo,
  ExtractCopiedUrl,
  ExtractType,
  OrderForm,
} from "types";

const useAddress = (
  setValue: UseFormSetValue<OrderForm>,
  setError: UseFormSetError<OrderForm>,
  clearErrors: UseFormClearErrors<OrderForm>,
) => {
  const handleAddrChange = (type: "pickup" | "dropoff") => () => {
    setValue(`${type}.address`, "");
    setValue(`${type}.place`, "");

    clearErrors(type);
  };

  const handleAddrBlur =
    (type: "pickup" | "dropoff") => (e: React.FocusEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setError(`${type}.address`, {
          type: "required",
          message: ERROR_MSG.FIELD,
        });
      } else {
        clearErrors(`${type}.address`);
      }
    };

  const handleAddrCopy =
    (type: "pickup" | "dropoff") =>
    (extractType: ExtractType, data: ExtractCopiedUrl["data"]) => {
      if (!data) return;

      if (extractType === "coord") {
        setValue(`${type}.coord.x`, data?.coord?.x ?? 0);
        setValue(`${type}.coord.y`, data?.coord?.y ?? 0);
      }

      if (extractType === "placeId") {
        setValue(`${type}.placeId`, data?.placeId);
      }
    };

  const handleAddrSelect =
    (type: "pickup" | "dropoff") => (addrInfos: AddrAutoCompleteInfo) => {
      setValue(`${type}.placeId`, addrInfos.placeId);
      setValue(`${type}.address`, addrInfos.address);
      setValue(`${type}.place`, addrInfos.place);
      setValue(`${type}.coord.x`, addrInfos.coord.x);
      setValue(`${type}.coord.y`, addrInfos.coord.y);

      clearErrors(`${type}.address`);
    };

  const resetAddrInfo = (type: "pickup" | "dropoff") => () => {
    setValue(`${type}.placeId`, "");
    setValue(`${type}.address`, "");
    setValue(`${type}.place`, "");
    setValue(`${type}.coord.x`, 0);
    setValue(`${type}.coord.y`, 0);
  };

  return {
    handleAddrChange,
    handleAddrBlur,
    handleAddrCopy,
    handleAddrSelect,
    resetAddrInfo,
  };
};

export default useAddress;
