import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

interface ToastItemProps {
  isClosing: boolean;
}

const scaleUp = keyframes`
  from { max-height: 0;}
  to {max-height: 100px;}
`;

const scaleDown = keyframes`
  from { max-height: 100px;}
  to { max-height: 0;}
 `;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-50%);}
  to { opacity: 1; transform: translateY(0)}
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0)}
  to { opacity: 0; transform: translateY(50%)}
 `;

export const ToastItem = styled.div<ToastItemProps>`
  ${({ isClosing }) => css`
    max-height: 0;
    overflow: visible;
    animation: 0.6s forwards ${isClosing ? scaleDown : scaleUp};

    & > div {
      animation: 0.3s forwards ${isClosing ? fadeOut : fadeIn};
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 14px;
    align-items: center;
    width: 350px;
    border-radius: 2px;
    padding: 16px 20px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.toast};

    & > svg {
      width: 20px;
      height: 20px;
    }

    & > p {
      ${theme.font.medium_14};
      color: ${theme.color.gray_80};
    }

    &[data-type="warning"] {
      svg {
        color: ${theme.color.yellow_20};
      }
    }
  `}
`;
