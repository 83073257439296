/**
 * NOTE: @source https://mygumi.tistory.com/416
 * NOTE: 노션 url: https://www.notion.so/coconutsilo/usePrompt-7b70c70c9c4e42c897e7ae88324dacd6
 */

import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { Transition } from "history";
import { useRecoilState } from "recoil";

import { PromptModal } from "components";
import { useModal } from "hooks";
import { isOpenPromptState } from "stores";

import useBlocker from "./useBlocker";

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */

const usePrompt = (when: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpenPrompt, setIsOpenPrompt] = useRecoilState(isOpenPromptState);

  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const confirmNavigation = useCallback(() => {
    setConfirmedNavigation(true);
    handleModalClose();
  }, []);

  const handleBlockedNavigation = useCallback(
    (tx: Transition) => {
      if (!confirmedNavigation && tx.location.pathname !== location.pathname) {
        setLastLocation(tx);

        handleModalOpen(
          "PromptModal",
          <PromptModal ref={modalRef} callbackFn={confirmNavigation} />,
        )();

        return false;
      }
      return true;
    },
    [confirmedNavigation, location.pathname],
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  // NOTE: when 값과 isOpenPrompt 값이 같아야 함
  useEffect(() => {
    setIsOpenPrompt(when);
  }, [when]);

  // NOTE: 페이지 이동시 prompt 끄기
  useEffect(() => {
    return () => {
      setIsOpenPrompt(false);
    };
  }, []);

  useBlocker(handleBlockedNavigation, isOpenPrompt);
};

export default usePrompt;
