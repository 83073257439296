/* NOTE: 위도, 경도
  1. 경도 === lng === x (-180 ~ 180)
  2. 위도 === lat === y (-90 ~ 90)
*/

export const extractCopiedUrl = (url: string) => {
  // NOTE: 전체적으로 위도, 경도를 추출하는 로직 (ex: 위도, 경도)
  const regexCoordDecimal = /([-0-9.]+),([-0-9.]+)/;
  const matchesCoordDecimal = regexCoordDecimal.exec(
    decodeURIComponent(url).replace("+", "").replace(" ", ""),
  );

  if (matchesCoordDecimal && matchesCoordDecimal.length >= 3) {
    const x = parseFloat(matchesCoordDecimal[2]);
    const y = parseFloat(matchesCoordDecimal[1]);

    if (x && y) {
      return {
        extractType: "coord",
        data: { coord: { x, y } },
      };
    }
  }

  // NOTE: 전체적으로 위도, 경도를 추출하는 로직 (ex: 17°58'54.6"N 102°36'07.2"E)
  const regexCoordDMS =
    /^(\d+)°(\d+)'(\d+\.\d+)"([NS])\s+(\d+)°(\d+)'(\d+\.\d+)"([EW])$/;
  const matchesCoordDMS = url.match(regexCoordDMS);

  if (matchesCoordDMS) {
    const latitudeDegrees = parseInt(matchesCoordDMS[1]);
    const latitudeMinutes = parseInt(matchesCoordDMS[2]);
    const latitudeSeconds = parseFloat(matchesCoordDMS[3]);
    const latitudeDirection = matchesCoordDMS[4];

    const longitudeDegrees = parseInt(matchesCoordDMS[5]);
    const longitudeMinutes = parseInt(matchesCoordDMS[6]);
    const longitudeSeconds = parseFloat(matchesCoordDMS[7]);
    const longitudeDirection = matchesCoordDMS[8];

    const latitudeDecimal =
      latitudeDegrees + latitudeMinutes / 60 + latitudeSeconds / 3600;
    const longitudeDecimal =
      longitudeDegrees + longitudeMinutes / 60 + longitudeSeconds / 3600;

    const x = longitudeDirection === "W" ? -longitudeDecimal : longitudeDecimal;
    const y = latitudeDirection === "S" ? -latitudeDecimal : latitudeDecimal;

    if (x && y) {
      return {
        extractType: "coord",
        data: { coord: { x, y } },
      };
    }
  }

  if (!url.includes("foursquare.com")) {
    // NOTE: foursquare인 경우 ShortenUrl을 OriginUrl로 받아온 후 주소 정보만 추출하는 로직
    const regexDecodedFoursquare = /\/v\/([^/]+)/;
    const matchesDecodedFoursquare = url.match(regexDecodedFoursquare);

    if (matchesDecodedFoursquare) {
      const extractedText = matchesDecodedFoursquare[1];

      if (extractedText) {
        return {
          extractType: "shortenAutocomplete",
          data: {
            text: extractedText,
          },
        };
      }
    }
  }

  if (url.includes("maps/place")) {
    const regexCoordDecimal2 = /place\/([^/]+)\/data/;
    const matchesCoordDecimal2 = regexCoordDecimal2.exec(url);

    const regexCoordDecimal3 = /!3d([-0-9.]+)!4d([-0-9.]+)/;
    const matchesCoordDecimal3 = regexCoordDecimal3.exec(url);

    // NOTE: '/place/Indochina+Bank,+Kolao+Tower,+1+Rue+23+Singha,+Vientiane/data=' 형태에서 주소 정보만 추출하는 로직
    const encodedUrl = url
      .split("/place/")[1]
      .split("/data=")[0]
      .replace(/,/g, "");

    if (encodedUrl) {
      const formattedUrl = encodedUrl.replaceAll("+", " ");

      return {
        extractType: "shortenAutocomplete",
        data: { text: formattedUrl },
      };
    }

    // NOTE: 위도, 경도 추출하는 로직1
    if (matchesCoordDecimal2 && matchesCoordDecimal2.length >= 2) {
      const coord = matchesCoordDecimal2[1].split("+");
      const x = parseFloat(coord[1]);
      const y = parseFloat(coord[0]);

      if (x && y) {
        return {
          extractType: "coord",
          data: { coord: { x, y } },
        };
      }
    }

    // NOTE: 위도, 경도 추출하는 로직2
    if (matchesCoordDecimal3 && matchesCoordDecimal3.length >= 3) {
      const x = parseFloat(matchesCoordDecimal3[2]);
      const y = parseFloat(matchesCoordDecimal3[1]);

      if (x && y) {
        return {
          extractType: "coord",
          data: { coord: { x, y } },
        };
      }
    }
  }

  if (url.includes("maps.google.com")) {
    // NOTE: 'q=Indochina+Bank,+Kolao+Tower,+1+Rue+23+Singha,+Vientiane&ftid=' 형태에서 주소 정보만 추출하는 로직
    const encodedUrl = url.split("q=")[1]?.split("&ftid")[0];

    if (encodedUrl) {
      const formattedUrl = encodedUrl.replace(/,\s*\+/g, ", ");

      return {
        extractType: "shortenAutocomplete",
        data: { text: formattedUrl },
      };
    }
  }

  if (
    url.includes("maps.app.goo.gl") ||
    url.includes("goo.gl/maps") ||
    url.includes("foursquare.com")
  ) {
    const parsedUrl = getSplittedShortenUrl(url);

    // NOTE: shortenUrl을 originUrl로 변환하는 로직
    const regexShorten = /\/([a-zA-Z0-9]+)$/;
    const matchesShorten = regexShorten.exec(parsedUrl.split("?")[0]);

    if (matchesShorten && matchesShorten.length >= 2) {
      const shortenUrl = matchesShorten[1];

      if (shortenUrl) {
        return {
          extractType: "shorten",
          data: {
            url: parsedUrl,
          },
        };
      }
    }
  }

  return {
    extractType: "defaultAutocomplete",
    data: null,
  };
};

const getSplittedShortenUrl = (url: string) => {
  if (!url.split("https")[0]) return url;

  return `https${url.split("https")[1]}`;
};
