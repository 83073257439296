/* eslint-disable no-param-reassign */
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { auth } from "contexts/AuthProvider";
import { ERROR_CODE } from "constants/index";
import { TokenService } from "utils/helper/tokenService";

const tokenService = TokenService.getInstance(auth);

const createShipperInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);

  return instance;
};

const onRequest = (config: InternalAxiosRequestConfig) => {
  if (!config) {
    config = {} as any;
  }

  if (!config.headers) {
    config.headers = {} as any;
  }

  const accessToken = tokenService.getAccessToken();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`; // TODO: 서비스에 맞게 수정 필요
  }

  return config;
};

const onRequestError = (err: AxiosError) => {
  return Promise.reject(err);
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

// TODO: 서비스에 맞게 조건문 수정 필요
const onResponseError = async (err: AxiosError<any>): Promise<unknown> => {
  const { response: errorResponse } = err;

  switch (errorResponse?.data.message) {
    case ERROR_CODE.INVALID_ACCESS_TOKEN:
      return tokenService.resetTokenAndReattemptRequest(err);
    case ERROR_CODE.DUPLICATED_SIGNIN_DETECTED:
      tokenService.expireSession(errorResponse?.data.message);
      break;
  }

  return Promise.reject(err);
};

const ax = createShipperInstance();

export { ax };
