import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CardWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.color.gray_30};
    padding: 31px;
  `}
`;

export const CardTitleWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.color.gray_40};
    padding-bottom: 8px;
  `}
`;

export const CardTitle = styled.span`
  ${({ theme }) => css`
    ${theme.font.bold_15};
    color: ${theme.color.gray_90};
  `}
`;

export const CardFavoriteButton = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    border: 0;
    color: ${theme.color.primary_20};
    background-color: transparent;

    &:disabled {
      color: ${theme.color.gray_50};
    }
  `}
`;
