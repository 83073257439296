import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const FavoriteItem = styled.button<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    display: grid;
    grid-template-columns: 64px 1fr;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    width: 100%;
    margin-bottom: 13px;
    border: 1px solid
      ${isSelected ? theme.color.orange_20 : theme.color.gray_30};
    border-radius: 2px;
    padding: 13px 0;
    background-color: ${isSelected ? theme.color.orange_10 : theme.color.white};
    cursor: pointer;
  `}
`;

export const IconBox = styled.div<{ hasColor?: boolean }>`
  ${({ theme, hasColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-left: 20px;
    border-radius: 50%;
    background-color: ${hasColor ? theme.color.orange_20 : theme.color.gray_50};

    & > svg > path {
      fill: ${theme.color.white};
    }
  `}
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  text-align: start;
`;

export const Place = styled.p`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    padding-bottom: 2px;
    color: ${theme.color.gray_90};
  `}
`;

export const Address = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    padding-bottom: 6px;
    color: ${theme.color.gray_60};
  `}
`;

export const FavoriteInfo = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_50};
  `}
`;
