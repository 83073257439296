import React from "react";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "components";
import { useModal } from "hooks";
import { ExclamIcon } from "assets";
import * as S from "./DeletedAccountModal.styled";

interface DeletedAccountModalProps {
  dial: string;
  phoneNumber: string;
}

const DeletedAccountModal = ({
  dial,
  phoneNumber,
}: DeletedAccountModalProps) => {
  const { t } = useTranslation();
  const { modalRef } = useModal();

  return (
    <ConfirmModal css={S.confirmModal} ref={modalRef} activeBtnName="Confirm">
      <ExclamIcon />
      <S.Title>{t("Deleted account")}</S.Title>
      <S.Desc>
        +{dial} {phoneNumber}{" "}
        {t(
          "has been deleted. You can sign up again after 7 days from the moment that you signed off.",
        )}
      </S.Desc>
      <S.AddDesc>
        {t(
          "*Please contact the help center if you've never signed off. (+856 20 55 355 159)",
        )}
      </S.AddDesc>
    </ConfirmModal>
  );
};

export default DeletedAccountModal;
