import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const coupons = (theme: Theme) => css`
  height: 650px;
  padding: 32px 0 0;

  & > div {
    padding: 0 32px;

    &:nth-of-type(2) {
      height: calc(100% - 140px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  & > div:last-of-type {
    padding: 16px 32px 32px;
    box-shadow: 0px -4px 4px 0px #0000000d;
    background-color: ${theme.color.white};
  }
`;

export const NodataWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    height: 100%;

    & > p {
      ${theme.font.medium_14};
      color: ${theme.color.gray_50};
    }
  `}
`;

export const CouponItem = styled.button<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 436px;
    margin-bottom: 16px;
    border: 1px solid
      ${isSelected ? theme.color.orange_20 : theme.color.gray_30};
    border-radius: 5px;
    padding: 16px 24px;
    background-color: ${isSelected ? theme.color.orange_10 : theme.color.white};
    cursor: pointer;

    &:disabled {
      background-color: ${theme.color.gray_20};

      & > p,
      li {
        opacity: 0.4;
      }
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${theme.font.bold_20};
    color: ${theme.color.gray_90};
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    color: ${theme.color.gray_90};
  `}
`;

export const ContentList = styled.ul`
  ${({ theme }) => css`
    padding-left: 24px;
    list-style: unset;

    & > li {
      ${theme.font.regular_12};
      color: ${theme.color.gray_60};
      text-align: start;
    }
  `}
`;

export const division = css`
  margin: 4px 0;
`;

export const Period = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_12};
    color: ${theme.color.gray_60};

    & > span {
      ${theme.font.regular_12};
      margin-right: 8px;
      color: ${theme.color.red_20};
    }
  `}
`;
