import axios from "axios";

import { renewAccessTokenAPI } from "apis";
import { AUTH_ALERT_MSG, ERROR_CODE } from "constants/index";
import type { KeyOf } from "types";
import { Auth } from "./auth";

interface Fn {
  (accessToken: string): void;
}

export class TokenService {
  private static instance: TokenService | null = null;

  static getInstance(auth: Auth): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService(auth);
    }
    return TokenService.instance;
  }

  private isAlreadyFetchingAccessToken = false;

  private subscribers: Fn[] = [];

  private constructor(private auth: Auth) {}

  async resetTokenAndReattemptRequest(error: any) {
    try {
      const { response: errorResponse } = error;

      const { refreshToken } = this.auth;
      if (!refreshToken) {
        this.expireSession(errorResponse?.data.message);
        return await Promise.reject(error);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        this.addSubscriber((accessToken: string) => {
          errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(errorResponse.config));
        });
      });
      if (!this.isAlreadyFetchingAccessToken) {
        try {
          this.isAlreadyFetchingAccessToken = true;
          const tokens = await renewAccessTokenAPI(refreshToken);
          if (!tokens.accessToken) {
            return await Promise.reject(error);
          }

          this.auth.changeAccessToken(tokens.accessToken);
          this.auth.changeRefreshToken(tokens.refreshToken);

          this.onAccessTokenFetched(tokens.accessToken);
        } catch (err: any) {
          const { response: errorResponse } = err;

          const authErrorCode = [
            ERROR_CODE.INVALID_REFRESH_TOKEN,
            ERROR_CODE.DUPLICATED_SIGNIN_DETECTED,
          ];

          if (authErrorCode.includes(errorResponse?.data.message)) {
            this.expireSession(errorResponse?.data.message);
            return await Promise.reject(err);
          }
        } finally {
          this.isAlreadyFetchingAccessToken = false;
        }
      }
      return await retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getAccessToken() {
    return this.auth.accessToken;
  }

  onAccessTokenFetched(accessToken: string) {
    this.subscribers.forEach((callback) => callback(accessToken));
    this.subscribers = [];
  }

  addSubscriber(callback: Fn) {
    this.subscribers.push(callback);
  }

  expireSession(errMsg: KeyOf<typeof AUTH_ALERT_MSG>) {
    this.auth.refreshToken && alert(AUTH_ALERT_MSG[errMsg]);
    this.auth.signOut();
  }
}
