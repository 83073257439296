export const color = {
  white: "#FFFFFF",
  white_00_04: "rgba(255, 255, 255, 0.04)",
  gray_00: "#FAFBFB",
  gray_10: "#FAFAFA",
  gray_20: "#F4F4F4",
  gray_30: "#EAEAEA",
  gray_35: "#E1E3E7",
  gray_40: "#D0D0D0",
  gray_50: "#A7A7A7",
  gray_50_50: "rgba(175, 181, 187, 0.5);",
  gray_60: "#888888",
  gray_70: "#6F6F6F",
  gray_80: "#525252",
  gray_90: "#313131",
  black: "#191F28",
  primary_10: "#4B93FF",
  primary_20: "#0066FF",
  primary_30: "#73A4FF",
  primary_40: "#508DFF",
  primary_50: "#3569CC",
  red_10: "#FFEEEE",
  red_20: "#FF0000",
  red_30: "#E42E2E",
  orange_10: "#FFFAF6",
  orange_20: "#FF7D05",
  orange_20_40: "rgba(255, 125, 5, 0.4)",
  orange_30: "#EA7000",
  yellow_20: "#F6C500",
  green_10: "#00D66F",
} as const;

export type ColorType = typeof color;
