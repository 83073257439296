import { PickupDropoffInfoWindow } from "components";
import { MARKER_ICON } from "assets";
import type { Location, KeyOf } from "types";

export const addLocationMarker = (
  map: google.maps.Map,
  position: Location,
  markerType: KeyOf<typeof MARKER_ICON>,
  infoWindowContent: { label: string; icon: string },
) => {
  const markers: google.maps.Marker[] = [];

  markers.forEach((marker) => marker.setMap(null));

  const marker = new google.maps.Marker({
    map,
    position,
    shape: { coords: [40, 40, 40], type: "circle" },
    optimized: false,
    icon: MARKER_ICON[markerType],
  });

  markers.push(marker);

  addPickupDropoffInfoWindow(map, marker, infoWindowContent);

  return marker;
};

const addPickupDropoffInfoWindow = (
  map: google.maps.Map,
  marker: google.maps.Marker,
  infoWindowContent: { label: string; icon: string },
) => {
  const infoWindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(0, 6),
    content: PickupDropoffInfoWindow(infoWindowContent),
  });

  infoWindow.open({ anchor: marker, map });

  return infoWindow;
};
