import React, { useRef } from "react";
import { useRecoilState } from "recoil";

import { modalState } from "stores";
import type { Modal } from "types";
import useKeyTrap from "./useKeyTrap";
import useKeyClose from "./useKeyClose";

const useModal = (isCloseKeyEnabled = true) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  const [modals, setModals] = useRecoilState(modalState);

  const handleModalOpen =
    (modalId: string, component: Modal["component"]) =>
    (e?: React.MouseEvent): void => {
      e?.stopPropagation();

      if (modals.some((modal) => modal.id === modalId)) return;

      document.body.style.cssText = "overflow-y: hidden";
      setModals([...modals, { id: modalId, component }]);
    };

  const handleModalClose = (): void => {
    const newModalComponents = modals.slice(0, -1);

    setModals(modals.slice(0, -1));

    if (newModalComponents.length === 0) {
      document.body.style.cssText = "overflow: overlay";
    }
  };

  useKeyTrap(modalRef, handleModalClose);
  isCloseKeyEnabled && useKeyClose(modalRef, handleModalClose);

  return {
    modalRef,
    modals,
    handleModalOpen,
    handleModalClose,
  };
};

export default useModal;
