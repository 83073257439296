import { useCallback, useEffect, useState } from "react";

import { useGetCountryNumberList } from "services";
import type { Country, CountryNumberListClientModel } from "types";

const INIT_COUNTRY_DATA: Country = {
  code: "LA",
  name: "Laos",
  dial: "856",
};

const useCountryList = (countryCode?: keyof CountryNumberListClientModel) => {
  const { data: countries } = useGetCountryNumberList();

  const [selectedCountry, setSelectedCountry] =
    useState<Country>(INIT_COUNTRY_DATA);

  const handleSelectCountryWithCode = useCallback(
    (code: string) => {
      if (!countries) return;

      const selected = countries[code];
      setSelectedCountry(selected);
    },
    [countries],
  );

  useEffect(() => {
    if (!countries || !countryCode) return;

    setSelectedCountry(countries[countryCode]);
  }, [countries, countryCode]);

  return {
    countries,
    selectedCountry,
    handleSelectCountryWithCode,
  };
};

export default useCountryList;
