import { useState, useEffect } from "react";
import type { UseFormSetValue, UseFormWatch } from "react-hook-form";

import { ITEM_TYPE_LOOK_UP } from "assets";
import type { DropdownOptionType, OrderForm } from "types";

const useSelectItemType = (
  watch: UseFormWatch<OrderForm>,
  setValue: UseFormSetValue<OrderForm>,
  dropdownItemTypes?: readonly DropdownOptionType[],
) => {
  const [selectItemType, setSelectItemType] = useState({ key: "", label: "" });

  const handleSelectItemType = (id: string) => {
    if (!dropdownItemTypes) return;

    setValue("item.itemType", id as keyof typeof ITEM_TYPE_LOOK_UP);

    const selectItemtype = dropdownItemTypes.filter(
      (dropdownItem) => dropdownItem.key === id,
    )[0];
    setSelectItemType(selectItemtype);
  };

  useEffect(() => {
    setValue("item.name", "");
  }, [watch("item.itemType")]);

  return { selectItemType, handleSelectItemType };
};

export default useSelectItemType;
