import { css } from "@emotion/react";

export const font = {
  regular_11: css`
    font-size: 1.1rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(16 / 11);
  `,
  regular_12: css`
    font-size: 1.2rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(20 / 12);
  `,
  regular_13: css`
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(20 / 13);
  `,
  regular_14: css`
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(22 / 14);
  `,
  regular_14_underline: css`
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(22 / 14);
    text-decoration: underline;
    text-decoration-skip-ink: none;
  `,
  regular_15: css`
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(24 / 15);
  `,
  regular_16: css`
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "NotoSans";
    line-height: calc(26 / 16);
  `,
  medium_13: css`
    font-size: 1.3rem;
    font-weight: 500;
    font-family: "NotoSans";
    line-height: calc(20 / 13);
  `,
  medium_14: css`
    font-size: 1.4rem;
    font-weight: 500;
    font-family: "NotoSans";
    line-height: calc(22 / 14);
  `,
  medium_15: css`
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "NotoSans";
    line-height: calc(24 / 15);
  `,
  medium_16: css`
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "NotoSans";
    line-height: calc(26 / 16);
  `,
  medium_18: css`
    font-size: 1.8rem;
    font-weight: 500;
    font-family: "NotoSans";
    line-height: calc(30 / 18);
  `,
  semibold_14: css`
    font-size: 1.4rem;
    font-weight: 600;
    font-family: "NotoSans";
    line-height: calc(22 / 14);
  `,
  bold_11: css`
    font-size: 1.1rem;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: calc(16 / 11);
  `,
  bold_15: css`
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: calc(24 / 15);
  `,
  bold_16: css`
    font-size: 1.6rem;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: calc(26 / 16);
  `,
  bold_18: css`
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: calc(30 / 18);
  `,
  bold_20: css`
    font-size: 2rem;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: calc(32 / 20);
  `,
  bold_28: css`
    font-size: 2.8rem;
    font-weight: 700;
    font-family: "NotoSans";
    line-height: calc(46 / 28);
  `,
} as const;

export type FontType = typeof font;
