import { atom } from "recoil";

import type { Modal, Toast } from "types";

const modalState = atom<Modal[]>({
  key: "modalState",
  default: [],
});

const toastState = atom<[] | Toast[]>({
  key: "toastState",
  default: [],
});

export { modalState, toastState };
