import React from "react";
import { useRecoilValue } from "recoil";

import { Portal } from "components";
import { modalState } from "stores";
import * as S from "./Modal.styled";

const Modal = () => {
  const modals = useRecoilValue(modalState);

  return (
    <Portal container="#modal-root" mounted={modals.length > 0}>
      {modals.map(({ id, component }) => (
        <S.Dim key={id}>{component}</S.Dim>
      ))}
    </Portal>
  );
};

export default Modal;
