import { useEffect, useRef, useState } from "react";
import {
  AutocompletePrediction,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { extractCopiedUrl } from "utils";
import type {
  AddrAutoCompleteInfo,
  ExtractCopiedUrl,
  ExtractType,
} from "types";
import useEncodedAddr from "./useEncodedAddr";
import useGeocodeAddr from "./useGeocodeAddr";
import useCoordAddr from "./useCoordAddr";

const useAddrAutoComplete = (
  inputValue: string,
  type: "pickup" | "dropoff",
  resetAddrInfo: () => void,
  handleAddrChange: () => void,
  handleAddrBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
  handleAddrSelect: (value: AddrAutoCompleteInfo) => void,
  handleAddrCopy: (
    extractType: ExtractType,
    data: ExtractCopiedUrl["data"],
  ) => void,
  handleSelectInput: (value: string) => void,
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [addrSuggestions, setAddrSuggestions] = useState<
    AutocompletePrediction[]
  >([]);

  const { originUrl, refetchOriginUrl, handleEncodeUrlSet } = useEncodedAddr();
  const { geoCode, getGeocode } = useGeocodeAddr();
  const {
    initCoord,
    locationCoordinate,
    refetchLocationCoordinate,
    handleCoordSet,
  } = useCoordAddr(type);

  const restrictedCountry =
    process.env.REACT_APP_TITLE_PREFIX === "dev-" ? ["la", "kr"] : ["la"];
  const searchOptions = {
    componentRestrictions: { country: restrictedCountry },
  };

  const fillInput = (value: string) => {
    handleAddrChange();
    handleSelectInput(value);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  const handleAutoCompleteBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setAddrSuggestions([]);
    handleAddrBlur(e);
  };

  const alterInputCopiedAddr = (value: string) => {
    const { extractType, data } = extractCopiedUrl(value);

    switch (extractType) {
      case "coord": {
        handleCoordSet({
          [type]: {
            coord: {
              x: data?.coord?.x ?? 0,
              y: data?.coord?.y ?? 0,
            },
            type,
          },
        });
        refetchLocationCoordinate();

        return;
      }

      case "shorten": {
        handleEncodeUrlSet(data?.url ?? "");
        fillInput(value);
        refetchOriginUrl();

        return;
      }

      case "placeId": {
        handleAddrCopy("placeId", data);
        fillInput(value);
        getGeocode(data?.url ?? "");

        return;
      }

      case "shortenAutocomplete": {
        if (data?.text) {
          showAutoCompleteDropdown(data?.text ?? "");
          fillInput(data?.text ?? "");

          return;
        }
      }
    }

    fillInput(value);
  };

  const handleAutoCompleteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    alterInputCopiedAddr(value);
  };

  const showAutoCompleteDropdown = (value: string) => {
    if (value) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input: value },
        (predictions) => {
          setAddrSuggestions(predictions || []);
        },
      );
    }
  };

  const handleAutoCompleteFocus = async () => {
    handleSelectInput("");
    setAddrSuggestions([]);
    resetAddrInfo();
    handleCoordSet(initCoord);
    handleEncodeUrlSet("");
  };

  const handleAutoCompleteSelect =
    (info: AutocompletePrediction) => async () => {
      if (!info) return;

      const address = info.description;
      const res = await geocodeByAddress(address);
      const latLng = await getLatLng(res[0]);
      const { terms, place_id: placeId } = info;
      const place = terms[0].value;

      const AddressSearchType: AddrAutoCompleteInfo = {
        address,
        place,
        placeId,
        coord: {
          x: latLng.lng,
          y: latLng.lat,
        },
      };

      inputRef.current?.blur();
      handleSelectInput(address);
      setAddrSuggestions([]);
      handleAddrSelect(AddressSearchType);
    };

  const handleAutoCompleteSearch = () => {
    showAutoCompleteDropdown(inputValue);
  };

  const handleAutoCompleteKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      showAutoCompleteDropdown(inputValue);
    }
  };

  useEffect(() => {
    if (locationCoordinate?.status === "OK") {
      const address = locationCoordinate.results[0].formatted_address;
      const separatedAddress =
        locationCoordinate.results[0].address_components[0];
      const hasPlusCodeFirstChar = separatedAddress.types.includes("plus_code");
      const formattedAddress = hasPlusCodeFirstChar
        ? address.replace(/,\s*/, ",")
        : address;

      // TODO: 위도, 경도 값을 가지고 autocomplete로 진행하는 것이 아닌 바로 setValue하기
      const addrAutoCompleteInfo = {
        address,
        location: separatedAddress.short_name,
        placeId: locationCoordinate.results[0].place_id,
        coord: {
          x: locationCoordinate.results[0].geometry.location.lng,
          y: locationCoordinate.results[0].geometry.location.lat,
        },
      };

      showAutoCompleteDropdown(formattedAddress);
      handleSelectInput(formattedAddress);
    }
  }, [locationCoordinate]);

  useEffect(() => {
    if (geoCode) {
      const location = {
        x: geoCode.geometry.location.lng(),
        y: geoCode.geometry.location.lat(),
      };

      const newLocation = {
        [type]: {
          coord: location,
          type,
        },
      };

      handleCoordSet(newLocation);
    }
  }, [geoCode]);

  useEffect(() => {
    if (originUrl) {
      alterInputCopiedAddr(originUrl);
    }
  }, [originUrl]);

  return {
    inputRef,
    addrSuggestions,
    searchOptions,

    handleMouseDown,
    handleAutoCompleteKeyPress,
    handleAutoCompleteBlur,
    handleAutoCompleteChange,
    handleAutoCompleteFocus,
    handleAutoCompleteSelect,
    handleAutoCompleteSearch,
  };
};

export default useAddrAutoComplete;
