import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import type { S3PresignedServerModel } from "types";

export const getS3FileFormData = (
  s3Info: S3PresignedServerModel,
  file: File | Blob,
) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(s3Info.data.fields)) {
    formData.append(key, value);
  }
  formData.append("Content-type", file.type);
  formData.append("file", file);

  return formData;
};

export const makeNewImageFileName = (file: Blob | File) => {
  const extension = file.name.split(".").at(-1)?.toLocaleLowerCase();

  return `${uuidv4()}.${extension}`;
};

export const compressedFile = (imgFile: File) => {
  const options = {
    maxSizeMB: 5,
    maxWidthOrHeight: 1440,
    useWebWorker: true,
  };

  return imageCompression(imgFile, options);
};
