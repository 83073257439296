import React from "react";

import { NotFound } from "pages";

interface Props {
  children: React.ReactNode;
}

interface State {
  notFoundError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { notFoundError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (error.name === "AxiosError") {
      this.setState({ notFoundError: true });
    }
  }

  resetErrorState() {
    this.setState({ notFoundError: false });
  }

  render() {
    if (this.state.notFoundError) {
      return <NotFound resetErrorState={this.resetErrorState} />;
    }
    return this.props.children;
  }
}
