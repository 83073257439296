import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormWatch } from "react-hook-form";

import { ConfirmModal } from "components";
import { useModal } from "hooks";
import { formatICTDateTime } from "utils";
import { ConfirmImg } from "assets";
import type { OrderForm } from "types";
import * as S from "./OrderConfirmModal.styled";

interface OrderConfirmModalProps {
  watch: UseFormWatch<OrderForm>;
}

const OrderConfirmModal = ({ watch }: OrderConfirmModalProps) => {
  const { t } = useTranslation();
  const { modalRef, handleModalClose } = useModal();

  const handleConfirm = () => handleModalClose();

  useEffect(() => {
    return () => {
      setTimeout(() => {
        window.location.reload();
      });
    };
  }, []);

  return (
    <ConfirmModal
      css={S.confirmModal}
      ref={modalRef}
      activeBtnName="Confirm"
      activeFn={handleConfirm}
    >
      <img src={ConfirmImg} alt="ConfirmImg" />
      <S.Title>{t("Your order has been received!")}</S.Title>
      <S.Time>
        <S.Desc>{t("Pick-up time")}</S.Desc>
        <p>{formatICTDateTime(watch("reqDatetime"), "DD/MM/YYYY HH:mm")}</p>
      </S.Time>
      <S.Desc>
        {t("If you want real-time tracking,\nplease checking our app.")}
      </S.Desc>
    </ConfirmModal>
  );
};

export default OrderConfirmModal;
