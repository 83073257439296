import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TextareaWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    border: 1px solid ${theme.color.gray_30};
    padding: 12px 0 40px 12px;

    &:focus-within {
      border: 1px solid ${theme.color.gray_90};
    }
  `}
`;

export const Textarea = styled.textarea`
  ${({ theme }) => css`
    ${theme.scrollbar};
    width: 100%;
    height: 100%;
    border: 0;
    color: ${theme.color.gray_90};

    &::placeholder {
      ${theme.font.regular_14};
      color: ${theme.color.gray_40};
    }

    &:disabled {
      color: inherit;
      background-color: inherit;
    }
  `}
`;

export const Length = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    position: absolute;
    bottom: 12px;
    right: 20px;
    color: ${theme.color.gray_40};
  `}
`;
