import React from "react";

import { CheckModal } from "components";
import { useModal } from "hooks";

const AmountCheckModal = () => {
  const { modalRef, handleModalClose } = useModal();

  return (
    <CheckModal
      ref={modalRef}
      title="Confirm the shipping amount"
      desc="The price has changed. Please check the price again."
      activeBtnName="Confirm"
      activeFn={handleModalClose}
    />
  );
};

export default AmountCheckModal;
