import { useState } from "react";

import { useGetOriginUrl } from "services";

const useEncodedAddr = () => {
  const [encodedUrl, setEncodedUrl] = useState<string>("");

  const { data: originUrl, refetch: refetchOriginUrl } = useGetOriginUrl(
    encodedUrl,
    { enabled: !!encodedUrl },
  );

  const handleEncodeUrlSet = (url: string) => {
    setEncodedUrl(url);
  };

  return {
    originUrl,
    refetchOriginUrl,
    handleEncodeUrlSet,
  };
};

export default useEncodedAddr;
