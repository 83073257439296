import { useMutation } from "@tanstack/react-query";

import { auth } from "contexts/AuthProvider";
import { loginAPI, logoutAPI } from "apis";
import type { APIError, LoginQueryModel, LoginServerModel } from "types";

export const useLogin = () => {
  return useMutation<LoginServerModel, APIError, LoginQueryModel>({
    mutationFn: (req) => loginAPI(req),
    onSuccess: (res) => {
      auth.signIn(res);
    },
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: () => logoutAPI(),
    onSuccess: () => {
      auth.signOut();
    },
  });
};
