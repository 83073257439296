import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { auth } from "contexts";
import {
  GhostBtn,
  Dropdown,
  LanguageCheckModal,
  PromptModal,
} from "components";
import { useDropdownValue, useLanguage, useModal } from "hooks";
import { useLogout } from "services";
import { isOpenPromptState } from "stores";
import { getLanguageCode } from "utils";
import {
  LANG_DROPDOWN_OPTIONS,
  Logo,
  TranslateIcon,
  TriangleIcon,
  LANGUAGE,
} from "assets";
import { PATH } from "constants/index";
import * as S from "./Header.styled";

const Header = () => {
  const navigate = useNavigate();

  const [isOpenPrompt, setIsOpenPrompt] = useRecoilState(isOpenPromptState);

  const languageCode = getLanguageCode();
  const { mutate: logoutMutate } = useLogout();
  const { handleModalOpen, handleModalClose, modalRef } = useModal();
  const { selectLang } = useLanguage();
  const { selectedOption, handleSelect } = useDropdownValue(
    LANG_DROPDOWN_OPTIONS,
    languageCode,
  );

  const handleSelectLanguage = (languageCode: string): void => {
    selectLang(languageCode);
    handleSelect(languageCode);
  };

  const handleLogout = (): void => {
    if (isOpenPrompt) {
      const cbFn = () => {
        logoutMutate(undefined, {
          onSuccess: () => navigate(PATH.root),
        });
        setIsOpenPrompt(false);
        handleModalClose();
      };
      handleModalOpen(
        "PromptModal",
        <PromptModal ref={modalRef} callbackFn={cbFn} />,
      )();

      return;
    }

    logoutMutate(undefined, {
      onSuccess: () => navigate(PATH.root),
    });
  };

  const handleOpenModal = (selectedLanguage: string): void => {
    if (selectedLanguage === auth.langCode) return;

    handleModalOpen(
      "LanguageCheckModal",
      <LanguageCheckModal
        selectedLanguage={selectedLanguage as keyof typeof LANGUAGE}
        handleSelectLanguage={handleSelectLanguage}
      />,
    )();
  };

  return (
    <S.HeaderWrapper>
      <Logo css={S.logoIcon} />
      <S.Wrapper>
        <S.LanguageWrapper>
          <TranslateIcon />
          <Dropdown
            css={S.dropdown}
            options={LANG_DROPDOWN_OPTIONS}
            selectedOption={selectedOption}
            Icon={TriangleIcon}
            handleSelect={handleOpenModal}
          />
        </S.LanguageWrapper>
        <GhostBtn
          css={S.logoutBtn}
          variant="ghost"
          label="Sign out"
          handleClickBtn={handleLogout}
        />
      </S.Wrapper>
    </S.HeaderWrapper>
  );
};

export default Header;
