import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { debounce, isEmpty, isEqual } from "lodash-es";
import dayjs from "dayjs";

import {
  AmountCheckModal,
  Button,
  DiscountPay,
  OrderConfirmModal,
  CouponCheckModal,
  PackageInfo,
  RecipientInfo,
  SenderInfo,
  Summary,
  Time,
  ItemCautionsModal,
} from "components";
import { useModal, usePickupTime, usePrompt, useToast } from "hooks";
import { usePostEstimation, usePostShipmentOrder } from "services";
import { ORDER_FORM } from "assets";
import { ERROR_MSG, PAYMENT_METHOD, TOAST_MSG } from "constants/index";
import type { OrderForm } from "types";

import { useAddress, useCoupon, useTime } from "./hooks";
import * as S from "./Order.styled";

const Order = () => {
  const { mutate: estimationMutate, isLoading: isEstimationLoading } =
    usePostEstimation();
  const {
    isLoading: isLatestEstimationLoading,
    mutate: latestEstimationMutate,
  } = usePostEstimation();
  const {
    isLoading: isShipmentOrderLoading,
    isSuccess: isShipmentOrderSuccess,
    mutate: shipmentOrderMutate,
  } = usePostShipmentOrder();

  const {
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
    setError,
    watch,
    register,
    handleSubmit,
  } = useForm<OrderForm>({
    mode: "all",
    defaultValues: ORDER_FORM,
  });
  const totalValues = {
    ...getValues(),
    item: {
      ...getValues().item,
      photo: undefined,
    },
  };

  const {
    handleAddrChange,
    handleAddrBlur,
    handleAddrCopy,
    handleAddrSelect,
    resetAddrInfo,
  } = useAddress(setValue, setError, clearErrors);
  const { saveEstimatedAmount, resetSelectCoupon } = useCoupon(setValue);
  const { shippingNowDate, isExpiredPickupTime } = usePickupTime(
    watch("reqDatetime"),
  );
  const { timeRadioState, handleClickTimeType } = useTime(clearErrors);
  const { modalRef, handleModalOpen } = useModal(false);
  const { addToast } = useToast();
  usePrompt(
    isShipmentOrderSuccess
      ? false
      : !isEqual(totalValues, ORDER_FORM) || !isEmpty(watch("item.photo")),
  );

  const isSubmitBtnDisabled =
    !watch("pickup.address") ||
    !watch("pickup.name") ||
    !watch("pickup.phone") ||
    !watch("dropoff.address") ||
    !watch("dropoff.name") ||
    !watch("dropoff.phone") ||
    (timeRadioState === "scheculeForLater" && !watch("reqDatetime")) ||
    !watch("item.itemType") ||
    !watch("item.weight") ||
    !watch("payment.price") ||
    !!Object.keys(errors).length ||
    isEstimationLoading;

  const checkPaymentTimeError = (data: OrderForm): boolean => {
    if (data.payment.paymentMethod === "card") return false;
    if (data.payment.paidTime.key) return false;

    setError("payment.paidTime", { type: "required" });
    addToast(TOAST_MSG.WARNING.PAID_TIME_REQUIRED);
    return true;
  };

  const checkItemPhotoError = (data: OrderForm): boolean => {
    if (!(data.item.photo instanceof FileList)) return false;

    setError("item.photo", { type: "required" });
    addToast(TOAST_MSG.WARNING.ITEM_PHOTO_REQUIRED);
    return true;
  };

  const handlePlaceOrder = (data: OrderForm): void => {
    let error = false;
    error = checkPaymentTimeError(data);
    error = checkItemPhotoError(data);

    if (error) return;

    latestEstimationMutate(
      {
        body: {
          puEta: dayjs(
            timeRadioState === "shippingNow"
              ? shippingNowDate
              : data.reqDatetime,
          ).toISOString(),
          puCoord: {
            x: data.pickup.coord.x,
            y: data.pickup.coord.y,
          },
          doCoord: {
            x: data.dropoff.coord.x,
            y: data.dropoff.coord.y,
          },
          itemWeight: +data.item.weight,
          itemType: data.item.itemType!,
          currency: "LAK",
          ...(data.payment.couponCode && {
            couponCode: data.payment.couponCode,
          }),
        },
      },
      {
        onSuccess: (res) => {
          const isDefferentEstimate =
            res.estimatedPrice !== watch("payment.price") ||
            res.levelDiscount !== watch("payment.levelDiscount") ||
            res.couponDiscount !== watch("payment.couponDiscount") ||
            res.insuranceFee !== watch("summary.insuranceFee");
          if (isDefferentEstimate) {
            handleModalOpen("AmountCheckModal", <AmountCheckModal />)();
            saveEstimatedAmount(res);
          } else {
            shipmentOrderMutate(
              {
                body: {
                  vehicleType: null,
                  currency: data.payment.currency,
                  puEta: dayjs(
                    timeRadioState === "shippingNow"
                      ? shippingNowDate
                      : data.reqDatetime,
                  ).toISOString(),
                  pickup: {
                    place: data.pickup.place,
                    address: data.pickup.address,
                    addressDetail: data.pickup.addressDetail
                      ? data.pickup.addressDetail
                      : null,
                    coord: { x: data.pickup.coord.x, y: data.pickup.coord.y },
                    ...(data.pickup.placeId && {
                      placeId: data.pickup.placeId,
                    }),
                    countryCode: data.pickup.countryCode,
                    countryDial: data.pickup.countryDial,
                    phone: data.pickup.phone,
                    name: data.pickup.name,
                  },
                  dropoff: {
                    place: data.dropoff.place,
                    address: data.dropoff.address,
                    addressDetail: data.dropoff.addressDetail
                      ? data.dropoff.addressDetail
                      : null,
                    coord: { x: data.dropoff.coord.x, y: data.dropoff.coord.y },
                    ...(data.dropoff.placeId && {
                      placeId: data.dropoff.placeId,
                    }),
                    countryCode: data.dropoff.countryCode,
                    countryDial: data.dropoff.countryDial,
                    phone: data.dropoff.phone,
                    name: data.dropoff.name,
                  },
                  item: {
                    type: data.item.itemType!,
                    name: data.item.name ? data.item.name : null,
                    photo: data.item.photo as Blob, // NOTE: 상단에서 FileList 조건문으로 제외했음에도 불구하고 타입이 잡히지 않아 단언 처리
                    weight: +data.item.weight,
                    ...(data.item.memo && {
                      memo: data.item.memo.trim(),
                    }),
                  },
                  payment: {
                    type: PAYMENT_METHOD,
                    ...(data.payment.paidTime.key && {
                      time: data.payment.paidTime.key,
                    }),
                    currency: data.payment.currency,
                    deliveryFee: data.payment.price,
                    levelDiscount: data.payment.levelDiscount,
                    couponDiscount: data.payment.couponDiscount,
                    ...(data.payment.couponCode && {
                      couponCode: data.payment.couponCode,
                    }),
                  },
                },
              },
              {
                onSuccess: () => {
                  handleModalOpen(
                    "OrderConfirmModal",
                    <OrderConfirmModal watch={watch} />,
                  )();
                },
                onError: (err) => {
                  switch (err.response?.data.message) {
                    case "INVALID_PU_ETA":
                      setError("reqDatetime", {
                        message: ERROR_MSG.INVALID_TIME,
                      });
                      break;

                    case "COUPON_EXPIRED":
                      handleModalOpen(
                        "CouponCheckModal",
                        <CouponCheckModal
                          makeSummary={makeSummary}
                          resetSelectCoupon={resetSelectCoupon}
                        />,
                      )();
                      break;
                  }
                },
              },
            );
          }
        },
        onError: (err) => {
          if (err.response?.data.message === "COUPON_EXPIRED") {
            handleModalOpen(
              "CouponCheckModal",
              <CouponCheckModal resetSelectCoupon={resetSelectCoupon} />,
            );
          }
        },
      },
    );
  };

  const handleError = () => {
    if (
      timeRadioState === "scheculeForLater" &&
      (!watch("reqDatetime") || isExpiredPickupTime)
    ) {
      setError("reqDatetime", {
        message: "Please select a time at least 30 minutes from now.",
      });
    }
  };

  const makeSummary = debounce((reqDatetime: string) => {
    if (!reqDatetime) return;

    estimationMutate(
      {
        body: {
          puEta: dayjs(reqDatetime).toISOString(),
          puCoord: {
            x: watch("pickup.coord.x"),
            y: watch("pickup.coord.y"),
          },
          doCoord: {
            x: watch("dropoff.coord.x"),
            y: watch("dropoff.coord.y"),
          },
          itemWeight: +watch("item.weight"),
          itemType: watch("item.itemType")!,
          currency: "LAK",
        },
      },
      { onSuccess: (res) => saveEstimatedAmount(res) },
    );
  }, 1000);

  useEffect(() => {
    if (
      watch("pickup.coord.x") &&
      watch("pickup.coord.y") &&
      watch("dropoff.coord.x") &&
      watch("dropoff.coord.y") &&
      watch("item.itemType") &&
      watch("item.weight")
    ) {
      if (
        watch("item.itemType") === null ||
        watch("item.weight") === "0" ||
        +watch("item.weight") > 50
      )
        return;

      resetSelectCoupon();
      // TODO: 시간관련 로직 리팩토링 필요
      const isShippingNow = timeRadioState === "shippingNow";
      makeSummary(isShippingNow ? shippingNowDate : watch("reqDatetime"));
      isShippingNow && setValue("reqDatetime", shippingNowDate);

      return () => makeSummary.cancel(); // NOTE: 핵심은 cancel 이 부분
    }
  }, [
    watch("pickup.coord.x"),
    watch("pickup.coord.y"),
    watch("dropoff.coord.x"),
    watch("dropoff.coord.y"),
    watch("item.itemType"),
    watch("item.weight"),
    watch("reqDatetime"),
    timeRadioState,
  ]);

  useEffect(() => {
    if (isExpiredPickupTime) {
      clearErrors("reqDatetime");
    }
  }, [watch("reqDatetime")]);

  useEffect(() => {
    handleModalOpen(
      "ItemCautionsModal",
      <ItemCautionsModal ref={modalRef} />,
    )();
  }, []);

  return (
    <S.Order>
      <S.LeftWrapper>
        <SenderInfo
          watch={watch}
          errors={errors}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          resetAddrInfo={resetAddrInfo("pickup")}
          handleAddrChange={handleAddrChange("pickup")}
          handleAddrBlur={handleAddrBlur("pickup")}
          handleAddrCopy={handleAddrCopy("pickup")}
          handleAddrSelect={handleAddrSelect("pickup")}
        />
        <RecipientInfo
          watch={watch}
          errors={errors}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          resetAddrInfo={resetAddrInfo("dropoff")}
          handleAddrChange={handleAddrChange("dropoff")}
          handleAddrBlur={handleAddrBlur("dropoff")}
          handleAddrCopy={handleAddrCopy("dropoff")}
          handleAddrSelect={handleAddrSelect("dropoff")}
        />
        <Time
          radioState={timeRadioState}
          setValue={setValue}
          clearErrors={clearErrors}
          error={errors.reqDatetime}
          handleClickRadioBtn={handleClickTimeType}
        />
        <PackageInfo
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
        <DiscountPay
          errors={errors}
          watch={watch}
          clearErrors={clearErrors}
          setValue={setValue}
          estimationMutate={estimationMutate}
          saveEstimatedAmount={saveEstimatedAmount}
          resetSelectCoupon={resetSelectCoupon}
        />
      </S.LeftWrapper>
      <S.RightWrapper>
        <Summary
          isEstimationLoading={isEstimationLoading}
          timeType={timeRadioState}
          watch={watch}
        />
        <Button
          isLoading={isLatestEstimationLoading || isShipmentOrderLoading}
          isDisabled={isSubmitBtnDisabled}
          variant="orangeLarge"
          label="Place order"
          handleClickBtn={handleSubmit(handlePlaceOrder, handleError)}
        />
      </S.RightWrapper>
    </S.Order>
  );
};

export default Order;
