import React, { memo } from "react";
import dayjs from "dayjs";

import { Calendar, Input, CalendarBtn } from "components";
import { CalendarIcon } from "assets";
import type { Languages } from "types";
import * as S from "./CalendarInput.styled";

interface CalendarInputProps {
  className?: string;
  type?: "date" | "free";
  value: string;
  selectedDate: string[];
  hasTime?: boolean;
  errorId?: string;
  hasError?: boolean;
  handleChangeDate: (date: dayjs.Dayjs[] | []) => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const CalendarInput = ({
  className,
  type,
  value,
  selectedDate,
  hasTime = false,
  errorId,
  hasError,
  handleChangeDate,
  handleFocusCondition,
  handleBlurCondition,
}: CalendarInputProps) => {
  return (
    <S.Root className={className}>
      <Input
        css={S.calendarInput}
        placeholder={"DD/MM/YYYY" as Languages}
        disabled
        value={value || ""}
        errorId={errorId}
        hasError={hasError}
      />
      <CalendarBtn
        css={S.calendarDialogBtn}
        popup={(dialogRef, isDialogOpen, handleDialogClose) => (
          <Calendar
            ref={dialogRef}
            isDialogOpen={isDialogOpen}
            hasTime={hasTime}
            as="dialog"
            type={type}
            selectedDate={selectedDate}
            handleChangeDate={handleChangeDate}
            handleDialogClose={handleDialogClose}
            handleFocusCondition={handleFocusCondition}
            handleBlurCondition={handleBlurCondition}
          />
        )}
      >
        <CalendarIcon />
      </CalendarBtn>
    </S.Root>
  );
};

export default memo(CalendarInput);
