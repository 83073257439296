import styled from "@emotion/styled";
import { css } from "@emotion/react";

import type { ButtonVariant } from "./Button";

const mixinContents = css`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

interface ButtonProps {
  isLoading: boolean;
  variant: ButtonVariant;
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, isLoading, variant }) => css`
    ${mixinContents};
    ${variant === "orangeSmall" && theme.button.orangeSmall};
    ${variant === "orangeMedium" && theme.button.orangeMedium};
    ${variant === "orangeLarge" && theme.button.orangeLarge};
    ${variant === "secondarySmall" && theme.button.secondarySmall};
    ${variant === "outlineSmall" && theme.button.outlineSmall};
    pointer-events: ${isLoading && "none"};
  `}
`;

export const LoadingContents = styled.div`
  ${mixinContents};
  visibility: hidden;
`;

export const loadingSpinner = css`
  position: absolute;
`;
