import { useCallback, useState } from "react";
import dayjs from "dayjs";

import { formatICTStandardDateTime } from "utils";

const useChangeCalendar = () => {
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const selectedDate = selectedDates[0];

  const handleChangeDate = useCallback(
    (date: dayjs.Dayjs[] | []) => {
      if (date.length === 0) {
        setSelectedDates([]);
      } else {
        const [startDate] = date.map((d) =>
          formatICTStandardDateTime(
            d.format("YYYY-MM-DDTHH:mm:ss"),
            "DD/MM/YYYY",
          ),
        );
        setSelectedDates([startDate]);
      }
    },
    [selectedDates],
  );

  return {
    selectedDates,
    selectedDate,
    handleChangeDate,
  };
};

export default useChangeCalendar;
