import React, { useState, useEffect, useRef } from "react";
import { UseMutateFunction, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import type { UseFormSetValue, UseFormWatch } from "react-hook-form";

import { DetailModal, Division, CouponCheckModal } from "components";
import { useGetInfiniteCoupons } from "services";
import { useModal } from "hooks";
import {
  formatICTDateTime,
  isCouponExpiredSoon,
  numberCommaParse,
  commaWithCurrencyUnit,
} from "utils";
import { NodataIcon } from "assets";
import type {
  OrderForm,
  Coupon,
  PostEstimationServerModel,
  PostEstimationQueryModel,
  APIError,
} from "types";
import * as S from "./CouponModal.styled";

interface CouponModalProps {
  watch: UseFormWatch<OrderForm>;
  setValue: UseFormSetValue<OrderForm>;
  estimationMutate: UseMutateFunction<
    PostEstimationServerModel,
    APIError,
    PostEstimationQueryModel,
    unknown
  >;
  saveEstimatedAmount: (amount: PostEstimationServerModel) => void;
}

const transportType = {
  kokkok: "KOKKOK",
  move: "Move",
  express: "Express",
} as const;

const CouponModal = React.forwardRef<HTMLDialogElement, CouponModalProps>(
  ({ watch, setValue, estimationMutate, saveEstimatedAmount }, ref) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const {
      data: couponList,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    } = useGetInfiniteCoupons();
    const { handleModalOpen, handleModalClose } = useModal();
    const lastCouponRef = useRef<HTMLButtonElement | null>(null);
    const [selectCouponCode, setSelectCouponCode] = useState<string | null>(
      watch("payment.couponCode") || null,
    );

    const handleSelectCoupon = (coupon: Coupon) => () => {
      setSelectCouponCode(coupon.code);
    };

    const handleSelect = () => {
      if (!selectCouponCode || !watch("item.itemType")) return;

      estimationMutate(
        {
          body: {
            puEta: watch("reqDatetime"),
            puCoord: {
              x: watch("pickup.coord.x"),
              y: watch("pickup.coord.y"),
            },
            doCoord: {
              x: watch("dropoff.coord.x"),
              y: watch("dropoff.coord.y"),
            },
            itemWeight: +watch("item.weight"),
            itemType: watch("item.itemType")!,
            currency: "LAK",
            couponCode: selectCouponCode,
          },
        },
        {
          onSuccess: (res) => {
            setValue("payment.couponCode", selectCouponCode);
            saveEstimatedAmount(res);
            handleModalClose();
          },
          onError: (err) => {
            if (err.response?.data.message === "COUPON_EXPIRED") {
              handleModalOpen("CouponCheckModal", <CouponCheckModal />)();
            }
          },
        },
      );
    };

    const disabledCoupon = (coupon: Coupon) => {
      if (watch("payment.price") === 0 || coupon.transportType !== "kokkok")
        return true;

      const calcCost =
        watch("payment.price") - (watch("payment.levelDiscount") ?? 0);

      if (coupon.discountType === "amount") {
        if (coupon.minPrice < calcCost) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    };

    const couponTitle = (coupon: Coupon) => {
      if (coupon.amount) {
        return `${numberCommaParse(coupon.amount)} LAK`;
      }

      return coupon.percentage === 100
        ? `Free shipping`
        : `${coupon.percentage}%`;
    };

    const coupons = couponList?.pages.flatMap((item) => item.coupons);

    useEffect(() => {
      if (lastCouponRef.current === null) return;

      const io = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting || !hasNextPage || isFetchingNextPage)
              return;

            fetchNextPage();
            observer.unobserve(entry.target);
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        },
      );

      io.observe(lastCouponRef.current);
    }, [coupons?.length]);

    useEffect(() => {
      return () => {
        queryClient.removeQueries({ queryKey: ["coupons"] });
      };
    }, []);

    return (
      <DetailModal
        css={S.coupons}
        ref={ref}
        title="Coupon"
        posBtnName="Confirm"
        posFn={handleSelect}
        isPosDisabled={!selectCouponCode}
        isBtnFloat
      >
        {coupons?.length !== 0 ? (
          coupons?.map((coupon, i) => (
            <S.CouponItem
              key={coupon.code}
              ref={(coupons?.length as number) - 1 === i ? lastCouponRef : null}
              disabled={disabledCoupon(coupon)}
              isSelected={selectCouponCode === coupon.code}
              onClick={handleSelectCoupon(coupon)}
            >
              <S.SubTitle>{coupon.name}</S.SubTitle>
              <S.Title>{couponTitle(coupon)}</S.Title>
              <S.ContentList>
                <li>
                  {t("Only available for")}{" "}
                  {transportType[coupon.transportType]}
                </li>
                {coupon.amount && (
                  <li>
                    {t("Available for purchases over")}{" "}
                    {commaWithCurrencyUnit({
                      price: coupon.minPrice,
                      currencyUnit: "₭",
                      showPlusSign: false,
                    })}
                  </li>
                )}
              </S.ContentList>
              <Division css={S.division} />
              <S.Period>
                {isCouponExpiredSoon(coupon.expired) && (
                  <span>{t("Expire soon")}</span>
                )}
                Until {formatICTDateTime(coupon.expired, "DD MMM YYYY, HH:mm")}
              </S.Period>
            </S.CouponItem>
          ))
        ) : (
          <S.NodataWrapper>
            <NodataIcon />
            <p>{t("No active coupon")}</p>
          </S.NodataWrapper>
        )}
      </DetailModal>
    );
  },
);

CouponModal.displayName = "CouponModal";

export default CouponModal;
