const PickupDropoffInfoWindow = (infoWindowContent: {
  label: string;
  icon: string;
}) => {
  return (
    `<div class="location-info-window">` +
    `<img src=${infoWindowContent.icon} alt="location-icon"/>` +
    `<span>${infoWindowContent.label}</span>` +
    "</div>"
  );
};

export default PickupDropoffInfoWindow;
