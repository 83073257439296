import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const PreviewImg = styled.label`
  input {
    display: none;
  }

  img {
    width: 168px;
    height: 168px;
    margin-bottom: 4px;
    object-fit: contain;
    cursor: pointer;
  }
`;

export const EmptyImg = styled.label<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 168px;
    margin-bottom: 4px;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_30};
    background-color: ${hasError ? theme.color.red_10 : theme.color.gray_10};
    cursor: pointer;

    input {
      display: none;
    }
  `}
`;
