import { useState, useCallback } from "react";

const useRadioBtn = <
  T extends readonly { key: T[number]["key"]; label: T[number]["label"] }[],
>(
  initState: T,
) => {
  const [radioState, setRadioState] = useState(initState[0].key);

  const handleClickRadioBtn = useCallback(
    (key: T[number]["key"]) => () => {
      setRadioState(key);
    },
    [],
  );

  return { radioState, handleClickRadioBtn };
};

export default useRadioBtn;
