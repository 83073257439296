import React, { useEffect } from "react";

const useKeyClose = (
  ref: React.RefObject<HTMLDialogElement> | null,
  handleClose: () => void,
) => {
  useEffect(() => {
    if (!ref?.current) return;
    ref.current.focus();

    const keyListenerMap = new Map([["Escape", handleClose]]);

    const handleKeyListener = (e: KeyboardEvent) => {
      const listener = keyListenerMap.get(e.key);
      typeof listener === "function" && listener();
    };

    window.addEventListener("keydown", handleKeyListener);

    return () => {
      window.removeEventListener("keydown", handleKeyListener);
    };
  }, [handleClose]);
};

export default useKeyClose;
