import React from "react";

import { HomeIcon, LoactionSmallIcon, WorkIcon } from "assets";
import type { Favorite, FavoriteForm } from "types";
import * as S from "./FavoriteItem.styled";

interface FavoriteItemProps {
  favorite: Favorite;
  lastFavoriteRef?: React.MutableRefObject<HTMLButtonElement | null> | null;
  selectedFavorite: FavoriteForm | null;
  handleCardSelect: (item: FavoriteForm) => () => void;
}

const FavoriteItem = ({
  favorite,
  lastFavoriteRef,
  selectedFavorite,
  handleCardSelect,
}: FavoriteItemProps) => {
  return (
    <S.FavoriteItem
      key={favorite.id}
      ref={lastFavoriteRef}
      isSelected={favorite.id === selectedFavorite?.id}
      onClick={handleCardSelect(favorite)}
    >
      <S.IconBox hasColor={favorite.type !== "others"}>
        {favorite.type === "home" ? (
          <HomeIcon />
        ) : favorite.type === "work" ? (
          <WorkIcon />
        ) : (
          <LoactionSmallIcon />
        )}
      </S.IconBox>
      <S.InfoBox>
        <S.Place>{favorite.place}</S.Place>
        <S.Address>
          {favorite.addressDetail && `(${favorite.addressDetail})`}{" "}
          {favorite.address}
        </S.Address>
        <S.FavoriteInfo>
          {favorite.name} ・ +{favorite.countryDial} {favorite.phone}
        </S.FavoriteInfo>
      </S.InfoBox>
    </S.FavoriteItem>
  );
};

export default FavoriteItem;
