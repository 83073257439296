import type {
  PostEstimationQueryModel,
  PostEstimationServerModel,
  PostShipmentOrderQueryModel,
} from "types";
import { makeNewImageFileName } from "utils";
import { ax } from "./axios";
import { postS3PresignedAPI } from "./common";

export const postEstimation = async (req: PostEstimationQueryModel) => {
  const { data } = await ax.post<PostEstimationServerModel>(
    "/kokkok/estimation",
    req.body,
  );

  return data;
};

// LINK: https://www.notion.so/coconutsilo/kokkok-c8b4200c079647cab58905f24a098c15 (app과 주문생성 코드 같이 사용)
export const postShipmentOrder = async (req: PostShipmentOrderQueryModel) => {
  const { photo } = req.body.item;

  if (photo) {
    const photoFileName = makeNewImageFileName(photo);
    const { status } = await postS3PresignedAPI(
      `package/${photoFileName}`,
      photo,
    );

    if (status === 204) {
      const temp: any = { ...req.body };
      temp.item.photo = photoFileName;
      const { data } = await ax.post("/kokkok", temp);

      return data;
    }
  } else {
    const { data } = await ax.post("/kokkok", req.body);

    return data;
  }
};
