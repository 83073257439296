import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const searchLocModal = css`
  width: 900px;
`;

export const LocInfoWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 8px;
    row-gap: 4px;
    align-items: center;
    width: 100%;
    height: 68px;
    margin-bottom: 8px;
    padding: 8px 12px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const Location = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_16};
    color: ${theme.color.gray_90};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Address = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    grid-column: 2;
    color: ${theme.color.gray_60};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Map = styled.section`
  height: 484px;
  margin: 0;
`;
