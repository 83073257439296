import { useEffect } from "react";
import i18n from "i18next";

import { auth } from "contexts";
import { getLanguageCode } from "utils";
import { LANGUAGE, LANG_DROPDOWN_OPTIONS } from "assets";

type LanguageCode = keyof typeof LANGUAGE;

const useLanguage = () => {
  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode as LanguageCode);
    auth.changeLanguageCode(languageCode as LanguageCode);
  };

  const selectLang = (selectedLang: string): void => {
    changeLanguage(selectedLang);

    setTimeout(() => window.location.reload(), 0); // NOTE: 순서 중요. setLanguage가 먼저 실행되어야 함.
  };

  const isExistLangCode = (languageCode?: LanguageCode): boolean => {
    if (!languageCode) return false;
    const availableLangCode = LANG_DROPDOWN_OPTIONS.map((option) => option.key);

    return availableLangCode.includes(languageCode as LanguageCode);
  };

  useEffect(() => {
    if (!auth.isAuth) return;
    const languageCode = getLanguageCode();

    if (!isExistLangCode(languageCode)) {
      selectLang("lo");
      return;
    }

    changeLanguage(languageCode);
  }, []);

  return { selectLang };
};

export default useLanguage;
