import { css } from "@emotion/react";

import { color } from "styles/themes/color";
import { font } from "styles/themes/font";

export const input = {
  default: css`
    ${font.regular_14};
    width: 100%;
    height: 40px;
    border: 1px solid ${color.gray_30};
    padding: 9px 12px;
    color: ${color.gray_90};

    &::placeholder {
      ${font.regular_14};
      color: ${color.gray_40};
    }

    &:focus {
      border: 1px solid ${color.gray_90};
    }

    &[aria-invalid="true"] {
      border-color: ${color.red_20};
    }
  `,
} as const;

export type InputType = typeof input;
