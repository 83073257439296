import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Login = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;

  & > svg {
    margin-bottom: 40px;
  }
`;

export const LoginForm = styled.form`
  ${({ theme }) => css`
    width: 528px;
    height: 438px;
    border: 1px solid ${theme.color.gray_30};
    padding: 64px;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    display: block;
    margin-bottom: 4px;
    color: ${theme.color.gray_60};
  `}
`;

export const InputWrapper = styled.div<{ hasError?: boolean }>`
  ${({ hasError }) => css`
    margin-bottom: ${!hasError && "24px"};
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const Input = styled.input<{ hasError: boolean }>`
  ${({ theme, hasError }) => css`
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_30};
  `}
`;

export const PhoneInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectorCountryCode = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    position: absolute;
    top: 50%;
    left: 13px;
    color: ${theme.color.gray_90};
    transform: translateY(-50%);
  `}
`;

export const mixinInput = (theme: Theme) => css`
  ${theme.font.regular_14};
  color: ${theme.color.gray_90};

  ::placeholder {
    color: ${theme.color.gray_40};
  }
`;

export const phoneInput = (theme: Theme) => css`
  ${mixinInput(theme)};
  padding-left: 58px;
`;

export const PasswordInputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;

    & > button {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      background-color: inherit;

      & > svg {
        fill: ${theme.color.gray_40};
      }
    }
  `}
`;

export const passwordInput = (theme: Theme) => css`
  ${mixinInput(theme)};
  padding-right: 47px;
`;

export const loginBtn = css`
  margin-bottom: 20px;
`;

export const SignUpWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 16px;

    & > svg {
      width: 32px;
      height: 32px;
    }

    & > span {
      ${theme.font.regular_13};
      width: 352px;
      color: ${theme.color.gray_60};
    }
  `}
`;

export const ErrorMessage = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: block;
    margin-top: 4px;
    color: ${theme.color.red_20};
  `}
`;

export const loginError = css`
  margin: 20px 0;
`;
