import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

import type { RadioStyleType } from "./RadioBtn";

export const RadioWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

const fillRadioSVG = (color: string) => {
  const svgPrefix =
    "data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23";
  const colorWithoutHash = color.replace("#", "");
  const svgSuffix =
    "' /%3E%3Ccircle cx='8' cy='8' r='5' fill='%23FF7D05' /%3E%3C/svg%3E";

  return `"${svgPrefix}${colorWithoutHash}${svgSuffix}"`;
};

const defaultLabelStyle = (theme: Theme, disabled?: boolean) => css`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > span {
    ${theme.font.regular_14};
    color: ${theme.color.black};
  }

  & > input[type="radio"] {
    display: none;
  }

  :hover {
    cursor: ${disabled ? "not-allowed" : "pointer"};

    input[type="radio"]:enabled + label {
      border: 1px solid ${theme.color.orange_20};
      cursor: pointer;
    }
  }

  input[type="radio"]:checked + label {
    border: 1px solid ${theme.color.gray_40};
    background: ${theme.color.orange_20} no-repeat center;
    background-image: url(${fillRadioSVG(theme.color.white)});
  }

  input[type="radio"]:disabled + label {
    :hover {
      cursor: not-allowed;
    }
  }

  input[type="radio"]:disabled + label {
    border: 1px solid ${theme.color.gray_40};
    background-color: ${theme.color.gray_10};
  }

  input[type="radio"]:checked:disabled + label {
    border: 1px solid ${theme.color.gray_40};
    background-color: ${theme.color.gray_10};
    background: no-repeat center;
    background-image: url(${fillRadioSVG(theme.color.gray_40)});
  }
`;

const buttonLabelStyle = (theme: Theme, isChecked?: boolean) => css`
  ${theme.font.medium_14};
  border: 1px solid ${isChecked ? theme.color.orange_20 : theme.color.gray_30};
  border-radius: 2px;
  padding: 6px 12px;

  & > label {
    display: none;
  }

  & > span {
    color: ${isChecked ? theme.color.orange_20 : theme.color.gray_70};
  }
`;

interface LabelProps {
  isChecked?: boolean;
  disabled?: boolean;
  styleType: RadioStyleType;
}

export const Label = styled.label<LabelProps>`
  ${({ isChecked, disabled, styleType, theme }) => css`
    ${defaultLabelStyle(theme, disabled)};
    ${styleType === "button" && buttonLabelStyle(theme, isChecked)}
  `}
`;

export const RadioBtn = styled.label`
  ${({ theme }) => css`
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid ${theme.color.gray_40};
    border-radius: 50%;
  `}
`;
