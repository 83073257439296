import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, CountryListDropdown } from "components";
import { DeletedAccountModal, NewAccountModal } from "components/domain/login";
import { useCountryList, useModal } from "hooks";
import { useLogin } from "services";
import {
  makeCryptoFunction,
  maxLengthPhoneNumber,
  onlyNumber,
  removeFirstStringZero,
} from "utils";
import { AppLogo, EyeAbledIcon, EyeDisabledIcon, Logo } from "assets";
import { PATH } from "constants/index";
import * as S from "./Login.styled";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: loginMutate } = useLogin();
  const { selectedCountry, countries, handleSelectCountryWithCode } =
    useCountryList();

  const {
    watch,
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    defaultValues: {
      phone: "",
      password: "",
      client: "web" as const,
    },
  });

  const [isLoginError, setIsLoginError] = useState(false);
  const [passwordType, setPasswordType] = useState<"password" | "text">(
    "password",
  );

  const { handleModalOpen } = useModal();

  const handleChangePasswordType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleSelectCountryInputReset = (code: string) => {
    if (selectedCountry) {
      setValue("phone", "");
    }

    return handleSelectCountryWithCode(code);
  };

  return (
    <S.Login>
      <Logo />
      <S.LoginForm
        onSubmit={handleSubmit((data) => {
          loginMutate(
            {
              countryDial: selectedCountry.dial,
              countryCode: selectedCountry.code,
              phone: data.phone,
              password: makeCryptoFunction(data.password),
              agent: data.client,
            },
            {
              onSuccess: () => navigate(PATH.order),
              onError: (err) => {
                switch (err.response?.data.message) {
                  case "CLIENT_NOT_FOUND":
                    handleModalOpen(
                      "NewAccountModal",
                      <NewAccountModal phoneNumber={watch("phone")} />,
                    )();
                    break;
                  case "ACCOUNT_UNDER_DELETION":
                    handleModalOpen(
                      "DeletedAccountModal",
                      <DeletedAccountModal
                        phoneNumber={watch("phone")}
                        dial={selectedCountry.dial}
                      />,
                    )();
                    break;
                  default:
                    setIsLoginError(true);
                }
              },
            },
          );
        })}
      >
        <S.Label>{t("Mobile number")}</S.Label>
        <S.InputWrapper>
          <S.FlexWrapper>
            <CountryListDropdown
              countries={countries}
              selectedCountry={selectedCountry}
              handleSelectCountryWithCode={handleSelectCountryInputReset}
            />
            <S.PhoneInputWrapper>
              <S.SelectorCountryCode>
                +{selectedCountry.dial}
              </S.SelectorCountryCode>
              <S.Input
                css={S.phoneInput}
                maxLength={maxLengthPhoneNumber(selectedCountry)}
                placeholder={t("Enter mobile number")}
                hasError={!!errors.phone || isLoginError}
                {...register("phone", {
                  required: true,
                  maxLength: maxLengthPhoneNumber(selectedCountry),
                  onChange: (e) => {
                    setIsLoginError(false);
                    setValue(
                      "phone",
                      removeFirstStringZero(onlyNumber(e.target.value)),
                    );
                  },
                })}
              />
            </S.PhoneInputWrapper>
          </S.FlexWrapper>
          {errors.phone && (
            <S.ErrorMessage>
              {t("Please enter 10~15 digit number.")}
            </S.ErrorMessage>
          )}
        </S.InputWrapper>
        <S.InputWrapper hasError={isLoginError}>
          <S.Label>{t("Password")}</S.Label>
          <S.PasswordInputWrapper>
            <S.Input
              css={S.passwordInput}
              type={passwordType}
              maxLength={20}
              placeholder={t("Enter password")}
              hasError={!!errors.password || isLoginError}
              {...register("password", {
                required: true,
                onChange: () => setIsLoginError(false),
              })}
            />
            <button type="button" onClick={handleChangePasswordType}>
              {passwordType === "password" ? (
                <EyeDisabledIcon />
              ) : (
                <EyeAbledIcon />
              )}
            </button>
          </S.PasswordInputWrapper>
          {errors.password && (
            <S.ErrorMessage>{t("Please fill out this field.")}</S.ErrorMessage>
          )}
        </S.InputWrapper>
        {isLoginError && (
          <S.ErrorMessage css={S.loginError}>
            {t("Unregistered mobile number or incorrect password.")}
          </S.ErrorMessage>
        )}
        <Button
          css={S.loginBtn}
          type="submit"
          isDisabled={!!Object.keys(errors).length}
          variant="orangeLarge"
          label="Sign in"
        />
        <S.SignUpWrapper>
          <AppLogo />
          <span>
            {t(
              "You can join as a member or reset password through the KOKKOK Move app.",
            )}
          </span>
        </S.SignUpWrapper>
      </S.LoginForm>
    </S.Login>
  );
};

export default Login;
