import React, { useEffect, useRef, useState } from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

import { Input } from "components";
import type { Languages } from "types";
import * as S from "./UnitInput.styled";

interface UnitInputProps {
  className?: string;
  hasError?: boolean;
  maxLength?: number;
  placeholder?: Languages;
  unit: "kg";
  register?: UseFormRegisterReturn<string>;
}

const UnitInput = ({
  className,
  hasError,
  maxLength,
  placeholder,
  unit,
  register,
}: UnitInputProps) => {
  const unitRef = useRef<HTMLLabelElement | null>(null);

  const [unitOffsetWidth, setUnitOffsetWidth] = useState(0);

  useEffect(() => {
    if (!unitRef?.current) return;

    setUnitOffsetWidth(unitRef.current.offsetWidth);
  }, [unitRef]);

  return (
    <S.InputWrapper className={className}>
      <Input
        css={S.input(unitOffsetWidth)}
        hasError={hasError}
        maxLength={maxLength}
        placeholder={placeholder && placeholder}
        register={register}
      />
      <S.Unit ref={unitRef}>{unit}</S.Unit>
    </S.InputWrapper>
  );
};

export default UnitInput;
