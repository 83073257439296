import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "components";
import type { Languages } from "types";
import * as S from "./GhostBtn.styled";

export type ButtonVariant =
  | "alert"
  | "alert_blue"
  | "alert_red"
  | "ghost"
  | "ghost_blue"
  | "ghost_red";

interface GhostBtnProps {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  variant: ButtonVariant;
  label: Languages;
  handleClickBtn?: (e: React.MouseEvent) => void;
}

const GhostBtn = ({
  className,
  isDisabled,
  isLoading = false,
  type = "button",
  variant,
  label,
  handleClickBtn,
}: GhostBtnProps) => {
  const { t } = useTranslation();

  return (
    <S.GhostBtn
      className={className}
      isLoading={isLoading}
      disabled={isDisabled}
      type={type}
      variant={variant}
      onClick={handleClickBtn}
    >
      {isLoading ? <LoadingSpinner /> : t(label)}
    </S.GhostBtn>
  );
};

export default GhostBtn;
