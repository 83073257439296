import React from "react";
import useScript from "react-script-hook";

import { Header } from "components";
import { getLanguageCode } from "utils";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const languageCode = getLanguageCode();
  const [loading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLEMAP_KEY}&libraries=places&language=${languageCode}`,
  });

  return (
    <>
      <Header />
      {!loading && <main>{children}</main>}
    </>
  );
};

export default PrivateLayout;
