import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const confirmModal = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: 16px 16px 32px;

  & > img {
    width: 220px;
    height: 220px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 12px;
    color: ${theme.color.gray_90};
  `}
`;

export const Time = styled.div`
  ${({ theme }) => css`
    margin-bottom: 12px;
    & > p:last-of-type {
      ${theme.font.bold_18};
      color: ${theme.color.orange_20};
    }
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    margin-bottom: 2px;
    color: ${theme.color.gray_60};
    text-align: center;
    white-space: pre-wrap;

    &:last-of-type {
      margin-bottom: 32px;
    }
  `}
`;

export const ConfirmBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    padding: 11px 50px;
    color: ${theme.color.gray_00};
    background-color: ${theme.color.orange_20};
  `}
`;
