export const TOAST_MSG = {
  SUCCESS: {
    TEST_SUCCESS: {
      type: "success",
      content: "Test Tosat Success",
    },
  },
  WARNING: {
    BOX_MINUS_ONE: {
      type: "warning",
      content: "Please select more than 1.",
    },
    BOXES_MORE_THAN_TEN: {
      type: "warning",
      content: "You can select up to 10 boxes each.",
    },
    OPTION_ALREADY_SELECTED: {
      type: "warning",
      content: "The option has been already selected.",
    },
    PICKUP_TIME_FAIL: {
      type: "warning",
      content: "Please change the Pick-up time to ‘Schedule for later’.",
    },
    PAID_TIME_REQUIRED: {
      type: "warning",
      content: "Please select who is going to pay",
    },
    ITEM_PHOTO_REQUIRED: {
      type: "warning",
      content: "Please upload a photo of the item.",
    },
  },
} as const;
