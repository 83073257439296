import { useGetItemType } from "services";
import { ITEM_TYPE_LOOK_UP } from "assets";
import { DropdownOptionType } from "types";

const useGetDropdown = () => {
  const { data: itemTypes } = useGetItemType();

  if (!itemTypes) return {};

  const dropdownItemTypes: readonly DropdownOptionType[] = itemTypes
    .slice(0)
    .reverse()
    .map((itemType) => ({
      key: itemType,
      label: ITEM_TYPE_LOOK_UP[itemType],
    }));

  return { dropdownItemTypes };
};

export default useGetDropdown;
