import React from "react";
import { useTranslation } from "react-i18next";
import type {
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import type { UseMutateFunction } from "@tanstack/react-query";

import { Button, Card, Dropdown, Input, LabelContent } from "components";
import { useModal } from "hooks";
import { commaWithCurrencyUnit } from "utils";
import { CloseCircleIcon, PAID_BY_OPTIONS } from "assets";
import type {
  APIError,
  OrderForm,
  PostEstimationQueryModel,
  PostEstimationServerModel,
} from "types";
import { CouponModal } from "./container";
import * as S from "./DiscountPay.styled";

interface DiscountPayProps {
  errors: FieldErrors<OrderForm>;
  setValue: UseFormSetValue<OrderForm>;
  watch: UseFormWatch<OrderForm>;
  clearErrors: UseFormClearErrors<OrderForm>;
  estimationMutate: UseMutateFunction<
    PostEstimationServerModel,
    APIError,
    PostEstimationQueryModel,
    unknown
  >;
  saveEstimatedAmount: (amount: PostEstimationServerModel) => void;
  resetSelectCoupon: () => void;
}

const DiscountPay = ({
  errors,
  setValue,
  watch,
  clearErrors,
  estimationMutate,
  saveEstimatedAmount,
  resetSelectCoupon,
}: DiscountPayProps) => {
  const { t } = useTranslation();

  const { handleModalOpen, modalRef } = useModal();

  const handlePaidTimeSelect = (key: string) => {
    const selectedIdx = PAID_BY_OPTIONS.findIndex(
      (option) => option.key === key,
    );

    if (selectedIdx < 0) {
      setValue("payment.paidTime", { key: "", label: "" });
      return;
    }

    if (errors.payment?.paidTime) {
      clearErrors("payment.paidTime");
    }

    setValue("payment.paidTime", PAID_BY_OPTIONS[selectedIdx]);
  };

  const handleModal = () => {
    handleModalOpen(
      "CouponModal",
      <CouponModal
        ref={modalRef}
        watch={watch}
        setValue={setValue}
        estimationMutate={estimationMutate}
        saveEstimatedAmount={saveEstimatedAmount}
      />,
    )();
  };

  const handleDeleteCoupon = () => {
    resetSelectCoupon();

    estimationMutate(
      {
        body: {
          puEta: watch("reqDatetime"),
          puCoord: {
            x: watch("pickup.coord.x"),
            y: watch("pickup.coord.y"),
          },
          doCoord: {
            x: watch("dropoff.coord.x"),
            y: watch("dropoff.coord.y"),
          },
          itemWeight: +watch("item.weight"),
          itemType: watch("item.itemType")!,
          currency: "LAK",
        },
      },
      { onSuccess: (res) => saveEstimatedAmount(res) },
    );
  };

  return (
    <Card css={S.card} title="Discounts and payment methods">
      <LabelContent direction="horizontal" label="Coupon" columnWidth={104}>
        <S.InputWrapper isSelectCoupon={!!watch("payment.couponDiscount")}>
          {!!watch("payment.couponDiscount") && (
            <S.CouponDeleteButtonWrapper>
              <S.CouponDiscount>
                {commaWithCurrencyUnit({
                  price: watch("payment.couponDiscount"),
                  currencyUnit: "₭",
                  showPlusSign: false,
                  showMinusSign: true,
                })}
              </S.CouponDiscount>
              <S.CouponDeleteButton type="button" onClick={handleDeleteCoupon}>
                <CloseCircleIcon />
              </S.CouponDeleteButton>
            </S.CouponDeleteButtonWrapper>
          )}
          <Button
            css={S.selectButton}
            label="Select"
            variant="outlineSmall"
            handleClickBtn={handleModal}
          />
        </S.InputWrapper>
      </LabelContent>
      <LabelContent
        isRequired
        direction="vertical"
        label="Payment methods"
        marginBottom={4}
      >
        <Input readOnly value={t("Cash / Lao QR")} />
      </LabelContent>
      <LabelContent
        isRequired
        columnWidth={104}
        direction="horizontal"
        label="Paid by"
      >
        <Dropdown
          hasError={!!errors.payment?.paidTime}
          placeholder="Sender or Recipient"
          options={PAID_BY_OPTIONS}
          selectedOption={watch("payment.paidTime")}
          handleSelect={handlePaidTimeSelect}
        />
      </LabelContent>
    </Card>
  );
};

export default DiscountPay;
