import React from "react";

import {
  WarningIcon,
  ChromeIcon,
  FirefoxIcon,
  EdgeIcon,
  SafariIcon,
} from "assets/icon";
import * as S from "./IESupport.styled";

const IESupport = () => {
  const browsers = {
    "Google Chrome": ChromeIcon,
    "Mozilla Firefox": FirefoxIcon,
    "Microsoft Edge": EdgeIcon,
    Safari: SafariIcon,
  };

  return (
    <S.IESupport>
      <h1 className="a11y">IE not supported</h1>
      <S.ContentWrapper>
        <WarningIcon />
        <S.ContentItem>
          <S.Title>Browser not supported</S.Title>
          <S.Desc>
            You&apos;re using a web browser we don&apos;t suppot. <br />
            Please use one of these options to improve your experience.
          </S.Desc>
        </S.ContentItem>
        {/* <S.ContentItem>
          <S.Title>지원하지 않는 브라우저입니다.</S.Title>
          <S.Desc>
            현재 지원하지 않는 브라우저를 사용하고 있습니다.
            <br />
            아래의 권장 브라우저를 사용하여 다시 시도해 주세요.
          </S.Desc>
        </S.ContentItem> */}
      </S.ContentWrapper>
      <S.BrowserWrapper>
        {Object.entries(browsers).map(([browser, Icon]) => (
          <S.BrowserItem key={browser}>
            <Icon />
            <span>{browser}</span>
          </S.BrowserItem>
        ))}
      </S.BrowserWrapper>
    </S.IESupport>
  );
};

export default IESupport;
