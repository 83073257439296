import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  getCountryNumberList,
  getItemType,
  getLocationCoordinate,
  getFavoritesAPI,
  getOriginUrl,
  getCouponListAPI,
} from "apis";
import type {
  CountryNumberListClientModel,
  GetCouponServerModel,
  GetFavoriteServerModel,
} from "types";

export const useGetCountryNumberList =
  (): UseQueryResult<CountryNumberListClientModel> => {
    return useQuery({
      queryKey: ["countryNumberList"],
      queryFn: () => getCountryNumberList(),
      select: (res) =>
        res.countries.reduce(
          (acc, value) => ({ ...acc, [value.code]: value }),
          {},
        ),
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
    });
  };

export const useGetItemType = () => {
  return useQuery({
    queryKey: ["itemType"],
    queryFn: () => getItemType(),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
  });
};

export const useGetLocationCoordinate = (
  latitude: number,
  longitude: number,
  language: string,
  options?: UseQueryOptions<unknown, AxiosError, any, string[]>,
) => {
  return useQuery({
    ...options,
    queryKey: [`${latitude}, ${longitude}`],
    queryFn: () => getLocationCoordinate(latitude, longitude, language),
    suspense: false,
  });
};

export const useGetInfiniteFavorites = () => {
  return useInfiniteQuery<
    GetFavoriteServerModel,
    unknown,
    GetFavoriteServerModel,
    string[]
  >({
    queryKey: ["favorites"],
    queryFn: ({ pageParam }) =>
      getFavoritesAPI({ query: { nextCursor: pageParam } }),
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor === null ? undefined : lastPage.nextCursor;
    },
  });
};

export const useGetOriginUrl = (
  url: string,
  options?: UseQueryOptions<unknown, AxiosError, any, string[]>,
) => {
  return useQuery({
    ...options,
    queryKey: [url],
    queryFn: () => getOriginUrl(url),
    suspense: false,
  });
};

export const useGetInfiniteCoupons = () => {
  return useInfiniteQuery<
    GetCouponServerModel,
    unknown,
    GetCouponServerModel,
    string[]
  >({
    queryKey: ["coupons"],
    queryFn: ({ pageParam }) =>
      getCouponListAPI({
        query: {
          nextCursor: pageParam,
          status: "active",
        },
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor === null ? undefined : lastPage.nextCursor;
    },
    suspense: false,
  });
};
