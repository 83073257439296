import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { DebouncedFunc } from "lodash-es";

import { CheckModal } from "components";
import { useModal } from "hooks";

interface CouponCheckModalProps {
  makeSummary?: DebouncedFunc<() => void>;
  resetSelectCoupon?: () => void;
}

const CouponCheckModal = ({
  makeSummary,
  resetSelectCoupon,
}: CouponCheckModalProps) => {
  const queryClient = useQueryClient();

  const { modalRef, handleModalClose } = useModal();

  const handleConfirm = () => {
    queryClient.removeQueries(["coupons"]);
    makeSummary && makeSummary();
    resetSelectCoupon && resetSelectCoupon();
    handleModalClose();
  };

  return (
    <CheckModal
      ref={modalRef}
      title="Coupon expired"
      desc="The coupon you selected has expired."
      activeBtnName="Confirm"
      activeFn={handleConfirm}
    />
  );
};

export default CouponCheckModal;
