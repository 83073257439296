import React from "react";

import { CheckModal } from "components";

interface PromptModalProps {
  callbackFn: () => void;
}
const PromptModal = React.forwardRef<HTMLDialogElement, PromptModalProps>(
  ({ callbackFn }, ref) => {
    return (
      <CheckModal
        ref={ref}
        title="Leave this page?"
        desc="Changes you made may not be saved."
        activeBtnName="Leave"
        closeBtnName="Cancel"
        activeFn={callbackFn}
      />
    );
  },
);

PromptModal.displayName = "PromptModal";

export default PromptModal;
