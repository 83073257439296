import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const favoriteList = (theme: Theme) => css`
  height: 650px;
  padding: 32px 0 0;

  & > div {
    padding: 0 32px;

    &:nth-of-type(2) {
      height: calc(100% - 140px);
      overflow-y: auto;
    }
  }

  & > div:last-of-type {
    padding: 16px 32px 32px;
    background-color: ${theme.color.white};
    box-shadow: 0px -4px 4px 0px #0000000d;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const NodataWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    height: 100%;

    & > p {
      ${theme.font.medium_14};
      color: ${theme.color.gray_50};
    }
  `}
`;
