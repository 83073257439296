import React from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "components";
import { useModal } from "hooks";
import { getLanguageCode } from "utils";
import { isAgreeCautionsState } from "stores";
import {
  ProhibitedItemImg,
  ProhibitedLabelImg,
  RestrictedItemImg,
  RestrictedLabelImg,
} from "assets";
import type { Languages } from "types";
import * as S from "./ItemCautionsModal.styled";

const ItemCautionsModal = React.forwardRef<HTMLDialogElement>((_, ref) => {
  const { t } = useTranslation();

  const setIsAgreeCautions = useSetRecoilState(isAgreeCautionsState);

  const languageCode = getLanguageCode();

  const { handleModalClose } = useModal();

  const handleCautionsAgree = (): void => {
    setIsAgreeCautions(true);
    handleModalClose();
  };

  return (
    <ConfirmModal
      ref={ref}
      css={S.modal}
      activeBtnName="Agree"
      linkName="View details"
      linkUrl={`${process.env.REACT_APP_RESTRICTIONS_SITE}/${languageCode}`}
      activeFn={handleCautionsAgree}
    >
      <S.Title>{t("Delivery restrictions" as Languages)}</S.Title>
      <S.Prohibition>
        <S.RestrictionTitle>
          <img src={ProhibitedLabelImg} alt="prohibit" draggable={false} />
          <span>{t("Prohibited items for the delivery")}</span>
        </S.RestrictionTitle>
        <img src={ProhibitedItemImg} alt="Prohibited Item" draggable={false} />
        <S.RestrictedItemWrapper>
          <S.RestrictedItem>{t("Illegal, hazardous items")}</S.RestrictedItem>
          <S.RestrictedItem>
            {t("Financial items and valuables")}
          </S.RestrictedItem>
          <S.RestrictedItem>{t("Human or animal related")}</S.RestrictedItem>
          <S.RestrictedItem>
            {t("Additional care and documentation needed items")}
          </S.RestrictedItem>
        </S.RestrictedItemWrapper>
      </S.Prohibition>
      <S.Restriction>
        <S.RestrictionTitle>
          <img src={RestrictedLabelImg} alt="restrict" draggable={false} />
          <span>{t("Restricted items for the delivery")}</span>
        </S.RestrictionTitle>
        <img src={RestrictedItemImg} alt="Restricted Item" draggable={false} />
        <S.RestrictedItemWrapper>
          <S.RestrictedItem>
            {t("Any unpacked or fragile items")}
          </S.RestrictedItem>
          <S.RestrictedItem>{t("Documents")}</S.RestrictedItem>
          <S.RestrictedItem>{t("Food")}</S.RestrictedItem>
          <S.RestrictedItem>{t("Used electronic device")}</S.RestrictedItem>
        </S.RestrictedItemWrapper>
      </S.Restriction>
    </ConfirmModal>
  );
});

ItemCautionsModal.displayName = "ItemCautionsModal";

export default ItemCautionsModal;
