export const zIndex = {
  HEADER: 1000,
  NAV: 100,
  CALENDAR: 51,
  MODAL: 10000,
  TOAST: 10000,
  TABLE_SELECT_ROW: 5,
  CHECKBOX: 10,
  TABLE_ROW_BTN: 10,
  SETTLEMENT_INFOFIELD_STICKY: 49,
  STICKY: 50,
  default: 1,
  dialog: 15,
  toast: 20,
  DROPDOWN: 51,
  GOOGLE_MAP_DRIVER_MARKER: 10001,
};

export type ZIndexType = typeof zIndex;
