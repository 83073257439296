// FIXME: 예시로 작성한 것입니다. 각 플랫폼에 맞게 수정해야합니다.
import axios from "axios";

import type {
  LoginQueryModel,
  LoginServerModel,
  TokensServerModel,
} from "types";
import { ax } from "../axios";

export const loginAPI = async (req: LoginQueryModel) => {
  const { data } = await ax.post<LoginServerModel>("/clients/signin", req);

  return data;
};

export const logoutAPI = () => ax.post("/clients/signout");

export const renewAccessTokenAPI = async (refreshToken: string) => {
  const { data } = await axios.get<TokensServerModel>(
    `${process.env.REACT_APP_BASE_URL}/clients/tokens`,
    {
      headers: { Authorization: `Bearer ${refreshToken}` },
    },
  );

  return data;
};
