import React from "react";
import { UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FavoriteModal } from "components";
import { useModal } from "hooks";
import { useGetInfiniteFavorites } from "services";
import type { Languages, OrderForm } from "types";
import * as S from "./Card.styled";

interface CardProps {
  className?: string;
  title: Languages;
  hasFavoriteBtn?: boolean;
  children: React.ReactNode;
  type?: "dropoff" | "pickup";
  setValue?: UseFormSetValue<OrderForm>;
  clearErrors?: UseFormClearErrors<OrderForm>;
  handleSelectInput?: (value: string) => void;
}

const Card = ({
  className,
  title,
  hasFavoriteBtn,
  children,
  type,
  setValue,
  clearErrors,
  handleSelectInput,
}: CardProps) => {
  const { t } = useTranslation();

  const { handleModalOpen, modalRef } = useModal();

  const { data: favoriteList } = useGetInfiniteFavorites();

  const handleModal = () => {
    if (!favoriteList) return;

    handleModalOpen(
      "FavoriteModal",
      <FavoriteModal
        ref={modalRef}
        type={type}
        setValue={setValue}
        clearErrors={clearErrors}
        handleSelectInput={handleSelectInput}
      />,
    )();
  };

  if (!favoriteList) return null;

  const lastFavoriteList = favoriteList.pages[favoriteList.pages.length - 1];

  return (
    <S.CardWrapper className={className}>
      <S.CardTitleWrapper>
        <S.CardTitle>{t(title)}</S.CardTitle>
        {hasFavoriteBtn && (
          <S.CardFavoriteButton
            disabled={
              lastFavoriteList.home === null &&
              lastFavoriteList.work === null &&
              lastFavoriteList.othersTotal === 0
            }
            onClick={handleModal}
          >
            {t("Favorite")}
          </S.CardFavoriteButton>
        )}
      </S.CardTitleWrapper>
      {children}
    </S.CardWrapper>
  );
};

export default Card;
