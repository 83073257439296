import { a11y } from "./a11y";
import { boxShadow } from "./boxShadow";
import { button } from "./button";
import { color } from "./color";
import { font } from "./font";
import { input } from "./input";
import { size } from "./size";
import { scrollbar } from "./scrollbar";
import { zIndex } from "./zIndex";

export const theme = {
  a11y,
  boxShadow,
  button,
  color,
  font,
  input,
  size,
  scrollbar,
  zIndex,
};
