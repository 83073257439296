import React, { useState, useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import type { UseFormClearErrors, UseFormSetValue } from "react-hook-form";

import { DetailModal } from "components";
import { useGetInfiniteFavorites } from "services";
import { useModal } from "hooks";
import { NodataIcon } from "assets";
import type { OrderForm, FavoriteForm } from "types";
import { FavoriteItem } from "./container";
import * as S from "./FavoriteModal.styled";

interface FavoriteModalProps {
  type?: "dropoff" | "pickup";
  setValue?: UseFormSetValue<OrderForm>;
  clearErrors?: UseFormClearErrors<OrderForm>;
  handleSelectInput?: (value: string) => void;
}

const FavoriteModal = React.forwardRef<HTMLDialogElement, FavoriteModalProps>(
  ({ type, setValue, clearErrors, handleSelectInput }, ref) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const {
      data: favoriteList,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    } = useGetInfiniteFavorites();

    const lastFavoriteRef = useRef<HTMLButtonElement | null>(null);
    const [selectedFavorite, setSelectedFavorite] =
      useState<FavoriteForm | null>(null);

    const { handleModalClose } = useModal();

    const handleCardSelect = (item: FavoriteForm) => () => {
      setSelectedFavorite(item);
    };

    const handleSelect = () => {
      if (!selectedFavorite || !type || !setValue || !clearErrors) return;

      setValue(`${type}.address`, selectedFavorite.address);
      setValue(`${type}.addressDetail`, selectedFavorite.addressDetail);
      setValue(`${type}.place`, selectedFavorite.place);
      setValue(`${type}.coord.x`, selectedFavorite.coord.x);
      setValue(`${type}.coord.y`, selectedFavorite.coord.y);
      setValue(`${type}.countryCode`, selectedFavorite.countryCode);
      setValue(`${type}.countryDial`, selectedFavorite.countryDial);
      setValue(`${type}.phone`, selectedFavorite.phone);
      setValue(`${type}.name`, selectedFavorite.name);
      setValue(`${type}.placeId`, selectedFavorite.id);

      clearErrors(`${type}.address`);
      clearErrors(`${type}.phone`);
      clearErrors(`${type}.name`);

      handleSelectInput && handleSelectInput(selectedFavorite.address);

      handleModalClose();
    };

    const favorites = {
      home: favoriteList?.pages[favoriteList.pages.length - 1].home,
      work: favoriteList?.pages[favoriteList.pages.length - 1].work,
      others: favoriteList?.pages.flatMap((item) => item.others),
    };

    const hasFovorite =
      favorites?.home !== null ||
      favorites?.work !== null ||
      favorites?.others?.length !== 0;

    useEffect(() => {
      if (lastFavoriteRef.current === null) return;

      const io = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting || !hasNextPage || isFetchingNextPage)
              return;

            fetchNextPage();
            observer.unobserve(entry.target);
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        },
      );

      io.observe(lastFavoriteRef.current);
    }, [favorites.others?.length]);

    useEffect(() => {
      return () => {
        queryClient.removeQueries({ queryKey: ["favorites"] });
      };
    }, []);

    return (
      <DetailModal
        css={S.favoriteList}
        ref={ref}
        title="Favorite"
        posBtnName="Select"
        posFn={handleSelect}
        isPosDisabled={!selectedFavorite}
        isBtnFloat
      >
        {hasFovorite ? (
          <>
            {favorites?.home !== null && (
              <FavoriteItem
                favorite={favorites?.home!}
                selectedFavorite={selectedFavorite}
                handleCardSelect={handleCardSelect}
              />
            )}
            {favorites?.work !== null && (
              <FavoriteItem
                favorite={favorites?.work!}
                selectedFavorite={selectedFavorite}
                handleCardSelect={handleCardSelect}
              />
            )}
            {favorites?.others?.length !== 0 &&
              favorites?.others?.map((favorite, i) => (
                <FavoriteItem
                  key={i}
                  lastFavoriteRef={
                    (favorites?.others?.length as number) - 1 === i
                      ? lastFavoriteRef
                      : null
                  }
                  favorite={favorite}
                  selectedFavorite={selectedFavorite}
                  handleCardSelect={handleCardSelect}
                />
              ))}
            {isFetchingNextPage && (
              <S.SpinnerWrapper>
                <ClipLoader color="#FF7D05" />
              </S.SpinnerWrapper>
            )}
          </>
        ) : (
          <S.NodataWrapper>
            <NodataIcon />
            <p>{t("No history")}</p>
          </S.NodataWrapper>
        )}
      </DetailModal>
    );
  },
);

FavoriteModal.displayName = "FavoriteModal";

export default FavoriteModal;
