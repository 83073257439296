import { useState, useEffect, useCallback } from "react";

import type { DropdownOptionType } from "types";

const useDropdownValue = (
  options: readonly DropdownOptionType[],
  initKey?: string,
) => {
  const [selectedOption, setSelectedOption] = useState({ key: "", label: "" });

  const findObj = useCallback(
    (key: string) => {
      const selectIndex = options.findIndex((item) => item.key === key);
      return options[selectIndex];
    },
    [options],
  );

  const handleSelect = useCallback((key: string) => {
    setSelectedOption(findObj(key));
  }, []);

  useEffect(() => {
    if (!initKey) return;

    initKey && setSelectedOption(findObj(initKey));
  }, []);

  return { selectedOption, handleSelect };
};

export default useDropdownValue;
