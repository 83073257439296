import React from "react";
import { useTranslation } from "react-i18next";

import { BaseModal } from "components";
import { useModal } from "hooks";
import type { Languages } from "types";
import * as S from "./CheckModal.styled";

interface CheckModalProps {
  className?: string;
  children?: React.ReactNode;
  title: Languages;
  desc: Languages;
  activeBtnName: Languages;
  closeBtnName?: Languages;
  activeFn?: () => void;
  closeFn?: () => void;
}

const CheckModal = React.forwardRef<HTMLDialogElement, CheckModalProps>(
  (
    {
      className,
      children,
      title,
      desc,
      activeBtnName,
      closeBtnName,
      activeFn,
      closeFn,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        <S.ConfirmHeader>
          <S.Title>{t(title)}</S.Title>
          <S.Desc>{t(desc)}</S.Desc>
        </S.ConfirmHeader>
        {children && children}
        <S.ConfirmFooter>
          {closeBtnName && (
            <S.CloseBtn type="button" onClick={closeFn ?? handleModalClose}>
              {t(closeBtnName)}
            </S.CloseBtn>
          )}
          <S.ConfirmBtn type="button" onClick={activeFn}>
            {t(activeBtnName)}
          </S.ConfirmBtn>
        </S.ConfirmFooter>
      </BaseModal>
    );
  },
);

export default CheckModal;
