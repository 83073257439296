import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import lo from "./lo.json";

export const fallbackLng = "lo";

export const resources = {
  en: { translation: en },
  lo: { translation: lo },
} as const;

export const languages = [
  resources.en.translation,
  resources.lo.translation,
] as const;

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
});

export default i18n;
