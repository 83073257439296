import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { GOOGLE_ANALYTICS_LIST, SERVICE_NAME } from "constants/index";

const useGoogleAnalytics = () => {
  const location = useLocation();

  ReactGA.initialize(process.env.REACT_APP_GA_ID);

  useEffect(() => {
    const gaName =
      GOOGLE_ANALYTICS_LIST[
        location.pathname as keyof typeof GOOGLE_ANALYTICS_LIST
      ] || "NotFound";
    const title = `${SERVICE_NAME}/${gaName}`;

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title,
    });
  }, [location]);
};

export default useGoogleAnalytics;
