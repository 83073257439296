import dayjs from "dayjs";

import {
  formatCompareMinute,
  formatICTStandardDateTime,
  formatTwoDigitsTime,
  getPickupTime,
} from "utils";

const usePickupTime = (reqTime?: string) => {
  const today = dayjs();
  const pickupTime = getPickupTime();
  const leastPickupMinute = formatCompareMinute(
    formatTwoDigitsTime(pickupTime.minute()),
  );
  const isNextHour = pickupTime.minute() > +leastPickupMinute;

  const leastPickupHour = formatTwoDigitsTime(
    isNextHour ? pickupTime.hour() + 1 : pickupTime.hour(),
  );

  const shippingNowDate = formatICTStandardDateTime(
    today
      .set("h", +leastPickupHour)
      .set("m", +leastPickupMinute)
      .format("YYYY-MM-DDTHH:mm:ss"),
  );

  const isExpiredPickupTime = pickupTime.isBefore(
    dayjs(reqTime, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm") ?? "",
  );

  return {
    today,
    pickupTime,
    shippingNowDate,
    leastPickupHour,
    leastPickupMinute,
    isExpiredPickupTime,
  };
};

export default usePickupTime;
