import React from "react";
import { useTranslation } from "react-i18next";

import { BaseModal, ExternalLink } from "components";
import { useModal } from "hooks";
import type { Languages } from "types";
import * as S from "./ConfirmModal.styled";

interface ConfirmModalProps {
  className?: string;
  children?: React.ReactNode;
  activeBtnName: Languages;
  linkName?: Languages;
  linkUrl?: string;
  activeFn?: () => void;
}

const ConfirmModal = React.forwardRef<HTMLDialogElement, ConfirmModalProps>(
  (
    { className, children, activeBtnName, linkName, linkUrl, activeFn },
    ref,
  ) => {
    const { t } = useTranslation();
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        {children && children}
        <S.ConfirmBtn type="button" onClick={activeFn ?? handleModalClose}>
          {t(activeBtnName)}
        </S.ConfirmBtn>
        {linkName && linkUrl && (
          <ExternalLink css={S.link} href={linkUrl} variant="ghostGray">
            {t(linkName)}
          </ExternalLink>
        )}
      </BaseModal>
    );
  },
);

export default ConfirmModal;
