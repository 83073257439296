export const LANG_DROPDOWN_OPTIONS = [
  { key: "en", label: "English" },
  { key: "lo", label: "ພາສາລາວ" },
];

export const LANGUAGE = {
  en: "en",
  lo: "lo",
} as const;

export const ITEM_TYPE_LOOK_UP = {
  others: "etc.",
  papers: "Documents",
  fragile: "Fragile items",
  electronics: "Used electronic device",
  foods: "Food / Dairy products",
  greens: "Fruit / Vegetable",
  meat: "Meat / Seafood",
};
