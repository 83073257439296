import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const baseModal = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 359px;
  padding: 16px 16px 32px;

  & > img {
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 8px;
    color: ${theme.color.gray_90};
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin-bottom: 8px;
    color: ${theme.color.gray_80};
    white-space: pre-wrap;
  `}
`;

export const AddDesc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin-bottom: 32px;
    color: ${theme.color.gray_60};
    white-space: pre-wrap;
  `}
`;

export const ConfirmBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    padding: 11px 50px;
    color: ${theme.color.gray_00};
    background-color: ${theme.color.orange_20};
  `}
`;

export const link = css`
  margin-top: 12px;
`;
