import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useGoogleAnalytics } from "hooks";

interface NotFoundProps {
  resetErrorState?: () => void;
}

const NotFound = ({ resetErrorState }: NotFoundProps) => {
  const location = useLocation();

  useGoogleAnalytics();

  useEffect(() => {
    return () => {
      resetErrorState && resetErrorState();
    };
  }, [location.pathname]);

  return <div>Page not found</div>;
};

export default NotFound;
