import React from "react";
import dayjs from "dayjs";

import type * as type from "types";
import FreeDate from "../../date/freeDate/FreeDate";

interface FreeDatesProps {
  datePicker: {
    monthYear: type.MonthYear;
    handleChangePrevMonth: () => void;
    handleChangeNextMonth: () => void;
  };
  calendar: {
    currentDate: dayjs.Dayjs[] | [];
    hoveredDate: string | dayjs.Dayjs;
    changeHoveredDate: (date: dayjs.Dayjs | string) => void;
    handleClickDate: (date: dayjs.Dayjs[]) => () => void;
  };
}

const FreeDates = ({ datePicker, calendar }: FreeDatesProps) => {
  return (
    <>
      {[...Array(datePicker.monthYear.firstDOW)].map((_, i) => (
        <FreeDate
          key={i}
          currentDate={calendar.currentDate}
          date={datePicker.monthYear.firstWeekPrevMonthDate.add(i, "d")}
          hoveredDate={calendar.hoveredDate}
          changeMonth={datePicker.handleChangePrevMonth}
          changeHoveredDate={calendar.changeHoveredDate}
          handleClick={calendar.handleClickDate}
        />
      ))}
      {[...Array(datePicker.monthYear.lastDate)].map((_, i) => {
        const date = datePicker.monthYear.startDate.add(i, "d");

        return (
          <FreeDate
            key={i}
            isThisMonth
            currentDate={calendar.currentDate}
            date={date}
            hoveredDate={calendar.hoveredDate}
            changeHoveredDate={calendar.changeHoveredDate}
            handleClick={calendar.handleClickDate}
          />
        );
      })}
      {[
        ...Array(
          42 - (datePicker.monthYear.firstDOW + datePicker.monthYear.lastDate),
        ),
      ].map((_, i) => (
        <FreeDate
          key={i}
          currentDate={calendar.currentDate}
          date={datePicker.monthYear.nextMonthStartDate.add(i, "d")}
          hoveredDate={calendar.hoveredDate}
          changeMonth={datePicker.handleChangeNextMonth}
          changeHoveredDate={calendar.changeHoveredDate}
          handleClick={calendar.handleClickDate}
        />
      ))}
    </>
  );
};

export default FreeDates;
