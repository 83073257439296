import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { upperCase } from "lodash-es";

import { Button, ErrorMessage } from "components";
import { MONTH, WEEKS, ArrowIcon, CalendarIcon, TimeIcon } from "assets";
import type { Languages } from "types";
import SingleDates from "./dates/singleDates/SingleDates";
import FreeDates from "./dates/freeDates/FreeDates";
import useCalendar from "./hooks/useCalendar";
import * as S from "./Calendar.styled";

interface CalendarProps {
  className?: string;
  isDialogOpen?: boolean;
  hasTime?: boolean;
  type?: Readonly<"date" | "free">;
  as?: React.ElementType & string;
  selectedDate: string[];
  handleChangeDate: (date: dayjs.Dayjs[] | []) => void;
  handleDialogClose: () => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Calendar = React.forwardRef<HTMLDialogElement, CalendarProps>(
  (
    {
      className,
      isDialogOpen = false,
      hasTime = false,
      as = "div",
      type = "date",
      selectedDate,
      handleChangeDate,
      handleDialogClose,
      handleFocusCondition,
      handleBlurCondition,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const { calendar, datePicker, timePicker } = useCalendar(
      isDialogOpen,
      hasTime,
      type,
      selectedDate,
      handleChangeDate,
      handleDialogClose,
      handleFocusCondition,
      handleBlurCondition,
    );

    const handleCancelCalendar = () => {
      calendar.handleReset();
      handleDialogClose();
    };

    return (
      <S.Root
        ref={ref}
        className={className}
        aria-modal="true"
        open={isDialogOpen}
        as={as}
      >
        <S.Header>
          <S.MonthYear
            type="button"
            aria-controls="month-list"
            aria-expanded={calendar.isOpenMonthDialog}
            aria-label="open month list"
            onClick={calendar.handleMonthDialog}
          >
            <S.MonthYearContent>
              <span>
                {t(
                  upperCase(
                    datePicker.monthYear.value.format("MMM"),
                  ) as Languages,
                )}
              </span>
              <span>{datePicker.monthYear.value.format("YYYY")}</span>
            </S.MonthYearContent>
            <S.OpenMonthWrapper>
              <ArrowIcon />
            </S.OpenMonthWrapper>
          </S.MonthYear>
          <S.MoveBtnWrapper>
            <S.MoveBtn
              type="button"
              aria-label="move previous month"
              onClick={
                calendar.isOpenMonthDialog
                  ? datePicker.handleChangePrevYear
                  : datePicker.handleChangePrevMonth
              }
            >
              <S.ArrowLeftIcon />
            </S.MoveBtn>
            <S.MoveBtn
              type="button"
              aria-label="move next month"
              onClick={
                calendar.isOpenMonthDialog
                  ? datePicker.handleChangeNextYear
                  : datePicker.handleChangeNextMonth
              }
            >
              <S.ArrowRightIcon />
            </S.MoveBtn>
          </S.MoveBtnWrapper>
        </S.Header>
        {calendar.isOpenMonthDialog ? (
          <S.MonthWrapper id="month-list">
            {Object.entries(MONTH).map(([label, value]) => (
              <li key={value}>
                <S.MonthBtn
                  type="button"
                  aria-current={
                    value === +datePicker.monthYear.currentMonth &&
                    datePicker.monthYear.year ===
                      datePicker.monthYear.currentYear
                  }
                  aria-selected={+datePicker.monthYear.month === value}
                  onClick={calendar.handleClickMonth(value)}
                >
                  {t(label as Languages)}
                </S.MonthBtn>
              </li>
            ))}
          </S.MonthWrapper>
        ) : (
          <>
            <S.WeekRow>
              {WEEKS.map((week, i) => (
                <li key={`${week}-${i}`}>{t(week as Languages)}</li>
              ))}
            </S.WeekRow>
            <S.DateRow
              className={className}
              data-status={type}
              onMouseLeave={calendar.handleMouseLeave}
            >
              {type === "date" && (
                <SingleDates datePicker={datePicker} calendar={calendar} />
              )}
              {type === "free" && (
                <FreeDates datePicker={datePicker} calendar={calendar} />
              )}
            </S.DateRow>
          </>
        )}
        {hasTime && (
          <S.DateTimeContainer>
            <S.DateTimeWrapper hasErr={!!timePicker.timeErr}>
              <S.DateWrapper>
                <CalendarIcon />
                {calendar.currentDate && (
                  <time>
                    {dayjs(calendar.currentDate[0]).format("DD/MM/YYYY")}
                  </time>
                )}
              </S.DateWrapper>
              <S.TimeWrapper>
                <TimeIcon />
                <S.TimeInput
                  value={timePicker.time}
                  onChange={timePicker.handleChangeTime}
                  onBlur={timePicker.handleBlurTime}
                />
              </S.TimeWrapper>
            </S.DateTimeWrapper>
            <ErrorMessage css={S.timeErrorMsg} message={timePicker.timeErr} />
          </S.DateTimeContainer>
        )}
        {!calendar.isOpenMonthDialog && (
          <S.BtnWrapper>
            <Button
              css={S.resetBtn}
              variant="secondarySmall"
              label="Cancel"
              handleClickBtn={handleCancelCalendar}
            />
            <Button
              css={S.applyBtn}
              isDisabled={calendar.isDisabledApplyBtn}
              variant="orangeSmall"
              label="Apply"
              handleClickBtn={calendar.handleApply}
            />
          </S.BtnWrapper>
        )}
      </S.Root>
    );
  },
);

Calendar.displayName = "Calendar";

export default Calendar;
