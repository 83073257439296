import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";

import * as S from "./LabelContent.styled";

interface LabelContentProps {
  className?: string;
  children: React.ReactNode;
  isRequired?: boolean;
  columnWidth?: number;
  direction: "horizontal" | "vertical";
  label: Languages;
  marginBottom?: number;
}

const LabelContent = ({
  className,
  children,
  isRequired,
  columnWidth,
  direction,
  label,
  marginBottom,
}: LabelContentProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper
      className={className}
      columnWidth={columnWidth}
      direction={direction}
      marginBottom={marginBottom}
    >
      <S.LabelWrapper>
        <S.Label isRequired={isRequired}>{t(label)}</S.Label>
      </S.LabelWrapper>
      {children}
    </S.Wrapper>
  );
};

export default LabelContent;
