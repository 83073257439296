import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const confirmModal = css`
  width: 360px;
  padding: 32px 16px;
  text-align: center;

  svg {
    width: 110px;
    height: 110px;
    margin-bottom: 24px;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 8px;
    color: ${theme.color.gray_90};
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin-bottom: 8px;
    color: ${theme.color.gray_80};
    white-space: pre-wrap;
  `}
`;

export const AddDesc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin-bottom: 32px;
    color: ${theme.color.gray_60};
    white-space: pre-wrap;
  `}
`;

export const ConfirmBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    padding: 11px 50px;
    color: ${theme.color.gray_00};
    background-color: ${theme.color.orange_20};
  `}
`;
