import { useMutation } from "@tanstack/react-query";

import { postEstimation, postShipmentOrder } from "apis";
import type {
  APIError,
  PostEstimationQueryModel,
  PostEstimationServerModel,
  PostShipmentOrderQueryModel,
} from "types";

export const usePostEstimation = () => {
  return useMutation<
    PostEstimationServerModel,
    APIError,
    PostEstimationQueryModel
  >({
    mutationFn: (req) => postEstimation(req),
  });
};

export const usePostShipmentOrder = () => {
  return useMutation<unknown, APIError, PostShipmentOrderQueryModel>({
    mutationFn: (req) => postShipmentOrder(req),
  });
};
