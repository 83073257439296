import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";

import { addLocationMarker } from "utils/helper/map/mapConfig";
import pickupMarkerSvg from "assets/icon/ic_pickupMarker.svg";
import dropoffMarkerSvg from "assets/icon/ic_dropoffMarker.svg";
import type { KeyOf, PickupDropoffCoord } from "types";

const useLocationMarker = (
  googleMap: google.maps.Map,
  pickupDropoffCoord: PickupDropoffCoord,
) => {
  const { t } = useTranslation();
  const [locationLatLngs, setLocationLatLngs] = useState<google.maps.LatLng[]>(
    [],
  );

  const INFO_WINDOW_TYPE = {
    pickup: {
      label: t("Pick-up"),
      icon: pickupMarkerSvg,
    },
    dropoff: {
      label: t("Drop-off"),
      icon: dropoffMarkerSvg,
    },
  } as const;
  const [markers, setMarkers] = useState<google.maps.Marker>();

  const addLocation = () => {
    if (!googleMap || isEmpty(pickupDropoffCoord)) return;

    setLocationLatLngs(
      Object.values(pickupDropoffCoord).map((location) => {
        const { lat, lng } = location.position;

        if (markers) {
          markers.setMap(null);
        }

        const marker = addLocationMarker(
          googleMap,
          location.position,
          location.type,
          INFO_WINDOW_TYPE[location.type as KeyOf<typeof INFO_WINDOW_TYPE>],
        );

        setMarkers(marker);

        return new google.maps.LatLng(lat, lng);
      }),
    );
  };

  useEffect(() => {
    addLocation();
  }, [googleMap, pickupDropoffCoord]);

  return { locationLatLngs };
};

export default useLocationMarker;
