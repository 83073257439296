import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "types";
import * as S from "./ErrorMessage.styled";

interface ErrorMessageProps {
  className?: string;
  message: string;
}

const ErrorMessage = ({ className, message }: ErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <S.ErrorMessage className={className} role="alert">
      {t(message as Languages)}
    </S.ErrorMessage>
  );
};

export default ErrorMessage;
