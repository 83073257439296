export const PATH = {
  root: "/",
  order: "/order",
  notFound: "/notFound",
};

export const SERVICE_NAME = "Client";

export const GOOGLE_ANALYTICS_LIST = {
  "/": "Login",
  "/order": "Order",
} as const;
