import { useState, useEffect, useRef } from "react";

import { DEFAULT_PICKUP_COORD } from "constants/index";

const useGoogleMap = (
  x: number,
  y: number,
  handleMapClick: (e: any) => void,
) => {
  const ref = useRef(null);
  const defaultMapRef = useRef<google.maps.Map>();
  const [googleMap, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    defaultMapRef.current = new google.maps.Map(ref.current!, {
      zoom: 16,
      streetViewControl: false,
      center: {
        lng: x || DEFAULT_PICKUP_COORD.lng,
        lat: y || DEFAULT_PICKUP_COORD.lat,
      },
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.HYBRID,
    });
    setMap(defaultMapRef.current);
    defaultMapRef.current.addListener("click", handleMapClick);
  }, []);

  return { ref, googleMap };
};

export default useGoogleMap;
