import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as P from "pages";
import { PrivateRoute, PublicRoute } from "components";
import { PATH } from "constants/index";

interface RouterProps {
  children: React.ReactNode;
}

const Router = ({ children }: RouterProps) => {
  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route>
          <Route path="*" element={<P.NotFound />} />
          <Route element={<PublicRoute />}>
            <Route path="/" element={<P.Login />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={PATH.order} element={<P.Order />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
