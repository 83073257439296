import { css } from "@emotion/react";

import { font } from "./font";
import { color } from "./color";

const mixinButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:disabled {
    opacity: 0.4;
  }

  & > svg {
    margin-right: 8px;
  }
`;

const buttonSize = {
  ghost: css`
    ${font.regular_14_underline};
  `,
  xSmall: css`
    height: 20px;
    padding: 0 4px;
  `,
  small: css`
    ${font.medium_14};
    height: 40px;
    padding: 0 16px;
  `,
  medium: css`
    ${font.medium_14};
    height: 44px;
    padding: 0 50px;
  `,
  large: css`
    ${font.regular_15};
    width: 100%;
    height: 48px;
  `,
};

const buttonStyle = {
  primary: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.primary_40};

    &:not(:disabled):hover {
      background-color: ${color.primary_50};
    }

    & svg {
      fill: ${color.white};
    }
  `,
  orange: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.orange_20};

    &:not(:disabled):hover {
      background-color: ${color.orange_30};
    }

    & svg {
      fill: ${color.white};
    }
  `,
  secondary: css`
    ${mixinButton};
    border: 1px solid ${color.gray_20};
    color: ${color.gray_60};
    background-color: ${color.gray_10};

    &:not(:disabled):hover {
      border: 1px solid ${color.gray_30};
      background-color: ${color.gray_20};
    }

    &:disabled {
      opacity: 0.6;
    }
  `,

  outline: css`
    ${mixinButton};
    border: 1px solid ${color.gray_35};
    color: ${color.gray_80};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.gray_10};
    }

    & svg {
      fill: ${color.gray_80};
    }
  `,
  error: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.red_20};

    &:not(:disabled):hover {
      background-color: ${color.red_30};
    }
  `,
  ghostPrimary: css`
    ${mixinButton};
    color: ${color.primary_20};

    &:not(:disabled):hover {
      color: ${color.primary_10};
    }
  `,
  // TODO: 디자이너님께 컴포넌트 추가 요청드린 상태로 Fix될 경우 재수정 필요
  ghostGray: css`
    ${mixinButton};
    color: ${color.gray_60};

    &:not(:disabled):hover {
      color: ${color.gray_70};
    }

    & svg {
      fill: ${color.gray_40};
    }
  `,
  ghostRed: css`
    ${mixinButton};
    color: ${color.red_20};

    &:not(:disabled):hover {
      color: ${color.red_30};
    }
  `,
};

export const button = {
  primarySmall: css`
    ${buttonStyle.primary};
    ${buttonSize.small};
  `,
  primaryLarge: css`
    ${buttonStyle.primary};
    ${buttonSize.large};
  `,
  orangeSmall: css`
    ${buttonStyle.orange};
    ${buttonSize.small};
  `,
  orangeMedium: css`
    ${buttonStyle.orange};
    ${buttonSize.medium};
  `,
  orangeLarge: css`
    ${buttonStyle.orange};
    ${buttonSize.large};
  `,
  secondarySmall: css`
    ${buttonStyle.secondary};
    ${buttonSize.small};
  `,
  outlineXSmall: css`
    ${buttonStyle.outline};
    ${buttonSize.xSmall};
  `,
  outlineSmall: css`
    ${buttonStyle.outline};
    ${buttonSize.small};
  `,
  errorSmall: css`
    ${buttonSize.small};
    ${buttonStyle.error};
  `,
  ghostPrimary: css`
    ${buttonStyle.ghostPrimary};
    ${buttonSize.ghost};
  `,
  ghostGray: css`
    ${buttonStyle.ghostGray};
    ${buttonSize.ghost};
  `,
  ghostRed: css`
    ${buttonStyle.ghostRed};
    ${buttonSize.ghost};
  `,
  ghost: css`
    ${font.regular_14};
    position: relative;
    padding: 0;
    color: ${color.gray_70};

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 4px;
      width: 100%;
      height: 0.5px;
      background-color: ${color.gray_70};
    }

    &:not(:disabled):hover {
      color: ${color.gray_80};

      &::before {
        background-color: ${color.gray_80};
      }
    }

    &:disabled {
      opacity: 0.6;
    }
  `,
  ghost_blue: css`
    ${font.regular_14};
    position: relative;
    padding: 0;
    color: ${color.primary_10};

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      height: 1px;
      background-color: ${color.primary_10};
    }

    &:not(:disabled):hover {
      color: ${color.primary_30};

      &::before {
        background-color: ${color.primary_30};
      }
    }

    &:disabled {
      color: ${color.primary_10};

      &::before {
        background-color: ${color.primary_10};
      }
    }
  `,

  ghost_red: css`
    ${font.regular_14};
    position: relative;
    padding: 0;
    color: ${color.red_20};

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      height: 1px;
      background-color: ${color.red_20};
    }

    &:not(:disabled):hover {
      color: ${color.red_30};

      &::before {
        background-color: ${color.red_30};
      }
    }

    &:disabled {
      color: ${color.red_10};

      &::before {
        background-color: ${color.red_10};
      }
    }
  `,

  alert: css`
    ${font.medium_15};
    position: relative;
    padding: 0;
    color: ${color.gray_50};

    &:not(:disabled):hover {
      color: ${color.gray_70};

      &::before {
        background-color: ${color.gray_70};
      }
    }

    &:disabled {
      opacity: 0.6;
    }
  `,

  alert_blue: css`
    ${font.medium_15};
    position: relative;
    padding: 0;
    color: ${color.primary_10};

    &:not(:disabled):hover {
      color: ${color.primary_30};

      &::before {
        background-color: ${color.primary_30};
      }
    }

    &:disabled {
      color: ${color.primary_10};

      &::before {
        background-color: ${color.primary_10};
      }
    }
  `,

  alert_red: css`
    ${font.medium_15};
    position: relative;
    padding: 0;
    color: ${color.red_20};

    &:not(:disabled):hover {
      color: ${color.red_30};

      &::before {
        background-color: ${color.red_30};
      }
    }

    &:disabled {
      color: ${color.red_10};

      &::before {
        background-color: ${color.red_10};
      }
    }
  `,
} as const;

export type BtnTheme = typeof button;
