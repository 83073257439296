import React from "react";
import { useTranslation } from "react-i18next";

import { Button, BaseModal } from "components";
import { useModal } from "hooks";
import type { Languages } from "types";
import * as S from "./DetailModal.styled";

interface DetailModalProps {
  className?: string;
  children?: React.ReactNode;
  posFnType?: "submit" | "button";
  isBtnFloat?: boolean;
  isPosLoading?: boolean;
  isPosDisabled?: boolean;
  closeBtnName?: Languages;
  posBtnName?: Languages;
  desc?: Languages;
  title: Languages;
  closeFn?: () => void;
  posFn?: () => void;
}

const DetailModal = React.forwardRef<HTMLDialogElement, DetailModalProps>(
  (
    {
      className,
      children,
      isBtnFloat,
      isPosLoading,
      isPosDisabled,
      closeBtnName = "Close",
      posBtnName,
      title,
      desc,
      posFnType = "button",
      closeFn,
      posFn,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        <S.DetailHeader>
          <S.Title>{t(title)}</S.Title>
          {desc && <S.DetailDesc>{t(desc)}</S.DetailDesc>}
        </S.DetailHeader>
        {children && <S.DetailContent>{children}</S.DetailContent>}
        <S.DetailInfoFooter isBtnFloat={isBtnFloat}>
          {closeBtnName && (
            <Button
              variant="secondarySmall"
              label={closeBtnName}
              handleClickBtn={closeFn ?? handleModalClose}
            />
          )}
          {posBtnName && (
            <Button
              isLoading={isPosLoading}
              isDisabled={isPosDisabled}
              type={posFnType}
              variant="orangeSmall"
              label={posBtnName}
              handleClickBtn={posFn && posFn}
            />
          )}
        </S.DetailInfoFooter>
      </BaseModal>
    );
  },
);

DetailModal.displayName = "DetailModal";

export default DetailModal;
