import { ContextType, useContext, useEffect } from "react";
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";
import type { Blocker, History, Transition } from "history";

interface Navigator extends BaseNavigator {
  block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

const useBlocker = (blocker: Blocker, when = true) => {
  const { navigator } = useContext(
    NavigationContext,
  ) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export default useBlocker;
