import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { CheckboxIcon } from "assets/icon";

interface DropdownWrapperProps {
  isOpen: boolean;
}

export const Dropdown = styled.div`
  position: relative;
  height: 40px;
`;

interface DropdownBtnProps {
  hasError?: boolean;
}

export const DropdownBtn = styled.button<DropdownBtnProps>`
  ${({ hasError, theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_30};
    padding: 0 12px;
    background-color: transparent;

    &[disabled] {
      background-color: ${theme.color.gray_20};
    }

    &[data-haserr="true"] {
      border-color: ${theme.color.red_20};
    }

    &[aria-expanded="true"] {
      border: 1px solid ${theme.color.black};

      & > svg {
        transform: rotate(180deg);
      }
    }

    & > svg {
      fill: ${theme.color.black};
    }
  `}
`;

export const SelectedValue = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    color: ${theme.color.gray_90};
  `}
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_40};
  `}
`;

export const CustomCheckboxIcon = styled(CheckboxIcon)`
  display: none;
  width: 12px;
  height: 8px;
`;

export const OptionWrapper = styled.ul<DropdownWrapperProps>`
  ${({ theme, isOpen }) => css`
    ${theme.scrollbar};
    position: absolute;
    display: ${isOpen ? "inherit" : "none"};
    top: calc(100% + 4px);
    width: 100%;
    max-height: 203px;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.DROPDOWN};
  `}
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const OptionBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 0 8px 0 12px;
    background-color: ${theme.color.white};
    word-break: break-all;

    & > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_80};
    }

    :hover {
      color: ${theme.color.gray_70};
      background-color: ${theme.color.gray_10};
    }

    &[data-selected="true"] {
      > span {
        color: ${theme.color.orange_20};
      }

      svg {
        display: block;

        path {
          fill: ${theme.color.orange_20};
        }
      }
    }
  `}
`;

export const NoOptionData = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    column-gap: 8px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 28px 0;
  `}
`;

export const noOptionIcon = css`
  width: 20px;
  height: 20px;
`;

export const noOptionDataMsg = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;

export const CustomOption = styled(Option)`
  ${({ theme }) => css`
    &:first-of-type {
      border-bottom: 1px solid ${theme.color.gray_30};
    }

    &:not(:last-of-type) {
      border-bottom: 0;
    }
  `}
`;

export const CustomOptionBtn = styled(OptionBtn)`
  position: relative;
  justify-content: flex-start;
  column-gap: 18px;
`;

export const CountryCode = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    color: ${theme.color.gray_80};
    min-width: 24px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      display: inline-block;
      height: 16px;
      margin-left: 16px;
      border-right: 1px solid ${theme.color.gray_30};
      text-align: center;
      transform: translateY(-50%);
    }
  `}
`;

export const CountryInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    flex: 1;
    margin-left: 8px;
    color: ${theme.color.gray_80};
    text-align: left;
    overflow: hidden;
  `}
`;

export const CustomOptionWrapper = styled(OptionWrapper)`
  width: 335px;
`;

export const CustomDropdownBtn = styled(DropdownBtn)`
  ${({ theme }) => css`
    width: 76px;

    & > span {
      ${theme.font.medium_14};
      color: ${theme.color.gray_90};
    }

    &[disabled] {
      pointer-events: none;
      background-color: ${theme.color.gray_10};
    }

    & > svg {
      fill: ${theme.color.gray_60};
    }
  `}
`;

export const DropdownSkeletonWrapper = styled.div`
  padding: 9px 12px;

  > span {
    display: flex;
  }
`;

export const Size = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 88px;
    text-align: start;
  `}
`;
