import { css } from "@emotion/react";

import { font } from "./font";
import { color } from "./color";

export const infoWindow = {
  location: css`
    .location-info-window {
      display: flex;
      align-items: center;
      column-gap: 8px;

      & > img {
        width: 28px;
        height: 28px;
      }

      & > span {
        ${font.medium_15};
        color: ${color.black};
      }
    }

    .gm-style-iw-c:has(.location-info-window) {
      top: 0;
      border: 1px solid ${color.gray_40};
      border-radius: 100px;
      padding: 4px 12px 4px 4px !important;
      box-shadow: none;

      > div {
        overflow: auto !important;
      }

      button {
        display: none !important;
      }

      ~ div {
        display: none;
      }
    }
  `,
};

export type InfoWindow = typeof infoWindow;
