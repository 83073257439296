import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { DropdownArrowIcon, ErrorIcon } from "assets/icon";
import type { DropdownOptionType, Languages } from "types";
import DropdownSkeleton from "./Dropdown.skeleton";
import useDropdown from "./hooks/useDropdown";
import * as S from "./Dropdown.styled";

interface DropdownProps {
  className?: string;
  isLoading?: boolean;
  isDisplayed?: (key: string) => boolean;
  disabled?: boolean;
  hasError?: boolean;
  options: readonly DropdownOptionType[];
  placeholder?: Languages;
  selectedOption: DropdownOptionType;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  handleSelect?: (value: string) => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Dropdown = ({
  className,
  isLoading = false,
  isDisplayed,
  disabled,
  hasError,
  options,
  placeholder,
  selectedOption,
  Icon = DropdownArrowIcon,
  handleSelect,
  handleFocusCondition,
  handleBlurCondition,
}: DropdownProps) => {
  const { dropdownRef, optionsRef, isOpen, handleOpener, handleClickOption } =
    useDropdown(
      selectedOption.key,
      handleSelect,
      handleFocusCondition,
      handleBlurCondition,
    );
  const id = uuidv4();

  const { t } = useTranslation();

  const checkOptions = (
    options: readonly DropdownOptionType[],
    option: string,
    i: number,
  ) => {
    if (!options[i].label.includes("cm")) {
      return <span>{t(option as Languages)}</span>;
    }

    return (
      <>
        <S.Size>{t(option.split("  ")[0] as Languages)}</S.Size>
        <span>{option.split("  ")[1]}</span>
      </>
    );
  };

  return (
    <S.Dropdown ref={dropdownRef} className={className}>
      <S.DropdownBtn
        aria-controls={id}
        aria-expanded={isOpen}
        hasError={hasError}
        disabled={disabled}
        type="button"
        onClick={handleOpener}
      >
        {selectedOption?.label ? (
          <S.SelectedValue>
            {t(selectedOption.label as Languages)}
          </S.SelectedValue>
        ) : (
          placeholder && <S.Placeholder>{t(placeholder)}</S.Placeholder>
        )}
        <Icon />
      </S.DropdownBtn>
      <S.OptionWrapper
        ref={optionsRef}
        aria-hidden={!isOpen}
        id={id}
        isOpen={isOpen}
      >
        {isLoading ? (
          [...Array(10)].map((_, i) => (
            <S.Option key={i}>
              <DropdownSkeleton />
            </S.Option>
          ))
        ) : options.length === 0 ? (
          <S.NoOptionData>
            <ErrorIcon css={S.noOptionIcon} />
            <S.noOptionDataMsg>{t("No history")}</S.noOptionDataMsg>
          </S.NoOptionData>
        ) : (
          options.map(({ key, label }, i) => {
            if (isDisplayed && !isDisplayed(key)) return null;

            return (
              <S.Option key={i}>
                <S.OptionBtn
                  data-selected={selectedOption?.key === key}
                  type="button"
                  onClick={handleClickOption(key)}
                >
                  {checkOptions(options, label, i)}
                  <S.CustomCheckboxIcon />
                </S.OptionBtn>
              </S.Option>
            );
          })
        )}
      </S.OptionWrapper>
    </S.Dropdown>
  );
};

export default Dropdown;
